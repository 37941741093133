import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async PayWithStripe (idToken, obj) {
    return await apiClient.post('payment/recarga', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async GetPayments (idToken) {
    return await apiClient.get('payment/',
      {
        headers: { idToken: idToken }
      })
  },

}
