// import Vue from 'vue'
// import App from './App.vue'
// import './registerServiceWorker'
// import router from './router'
// import store from './store'

// Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@/styles/index.scss'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import '../public/main.css'
import '@/firebase/init.js'

Vue.use(Buefy)

let devtools = false
if (process.env.VUE_APP_DEVTOOLS === 'true') {
  devtools = true
}

// console.log('hhhd -->', process.env.VUE_APP_DEVTOOLS, devtools)

Vue.config.productionTip = false
Vue.config.devtools = devtools

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})