import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async updateSkillLevel (idToken, obj) {
    return await apiClient.post('skilllevel/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async getSkillLevel () {
    return await apiClient.get('skilllevel/')
  },
  async newTypeOfStudent (idToken, obj) {
    return await apiClient.post('typeofstudent/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async updateTypeOfStudent (idToken, obj, id_typeofstudent) {
    return await apiClient.put(`typeofstudent/${id_typeofstudent}`, obj,
      {
        headers: { idToken: idToken }
      })
  },
  async getTypesOfStudents () {
    return await apiClient.get('typeofstudent/')
  },
  async getTypeOfStudent (id_typeofstudent) {
    return await apiClient.get(`typeofstudent/${id_typeofstudent}`)
  },
  async deleteTypeOfStudent (idToken, id_typeofstudent) {
    return await apiClient.delete(`typeofstudent/${id_typeofstudent}`, {
      headers: { idToken: idToken }
    })
  },
  async newDiscount (idToken, obj) {
    return await apiClient.post('recarga/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async updateRecarga (idToken, obj, id_recarga) {
    return await apiClient.put(`recarga/${id_recarga}`, obj,
      {
        headers: { idToken: idToken }
      })
  },
  async getRecargas () {
    return await apiClient.get('recarga/')
  },
  async getRecarga (id_recarga) {
    return await apiClient.get(`recarga/${id_recarga}`)
  },
  async deleteRecarga (idToken, id_recarga) {
    return await apiClient.delete(`recarga/${id_recarga}`,
    {
      headers: { idToken: idToken }
    })
  }
}
