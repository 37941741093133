<template>
  <div>
    <a @click="closeModal">
      <div class="button-close mt-5">
        <svg
          width="18"
          height="18"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 1L1 25"
            stroke="#000"
            stroke-width="4"
          />
          <path
            d="M1 1L25 25"
            stroke="#000"
            stroke-width="4"
          />
        </svg>
      </div>
    </a>

    <div class="container-content-create-account has-text-left mt-6">

      <div class="title-header-section-create-account">
        <p class="black">Ingresar</p>
      </div>

      <form class="form-login">
        <!-- user -->
        <!-- <b-field class="mt-5" label="Email">
          <b-input
            placeholder="tuemail@algo.com"
            v-model="email"
            type="email"
          >
          </b-input>
        </b-field> -->

        <!-- eMail -->
        <b-field
          class="mt-3"
          label="Correo Electrónico"
          :message="mensajeEmail"
          :type="messageTypeEmail"
        >
          <b-input
            v-on:focusout.native="ValidityEmail"
            v-on:focusin.native="clearValidityEmail"
            :validation-message= mensaje
            id="email"
            placeholder="youremail@something.com"
            v-model="email"
            autocomplete="off"
          >
          </b-input>
        </b-field>


        <!-- Pasword -->
        <b-field class="mt-5" label="Contraseña">
          <b-input type="password" v-model="password" password-reveal> </b-input>
        </b-field>

        <!-- reset password -->
         <div class="mt-1">
          <!-- <a href="https://api.whatsapp.com/send?phone=+34643943764&text=Hola, Nececito mas informacion!" target="_blank" class="text-basier" style="font-size: 13px; color: #9A55F2;" @click="resetPassword">Did you forget your password2? <span style="font-weight: bold; text-decoration: underline;">Click here</span> </a> -->
          <a
            class="link-text-login montserrat-medium orange"
            @click="resetPassword">
            ¿Olvidaste tu contraseña?
            <span
              class="montserrat-bold">
              Click aquí</span> </a>
        </div>

        <!-- button login -->
        <button
          class="mt-6 button-primary-medium" @click.prevent="login">Ingreso</button>
        <div style="margin-top: 59px;">
          <a
            @click.prevent="signupModal"
            class="OpenSans black link-text-login">
            Aún no tengo una cuenta
            <span
              class="montserrat-bold orange">
              Registrarme
            </span>
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// import dayjs from 'dayjs'

// var utc = require('dayjs/plugin/utc')
// var timezone = require('dayjs/plugin/timezone')

// dayjs.extend(utc)
// dayjs.extend(timezone)

export default {
  name: 'Login',
  metaInfo: {
    title: 'Kontraola | Login'
  },
  data () {
    return {
      email: '',
      password: '',
      user: {},

      mensajeEmail: '',
      messageTypeEmail: '',
      mensaje: ''

    }
  },
  created () {
  },
  methods: {
    // switch to signup
    signupModal () {
      this.$store.commit('openModal', 'signup')
    },

    // close modal
    closeModal () {
      this.$store.commit('closeModal')
    },
    // Login with Password --------
    async login () {
      // Login with firebase --> state.authUser
      await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then ( async () => {
          // this.$router.push('/panel').catch(()=>{})
          this.$store.commit('closeModal')
          // await firebase.auth().currentUser.getIdToken(true)
          //   .then(async idToken => {
          //     await UserService.SigninFirebase(idToken)
          //       .then(response => {
          //         if (response.status === 200) {
          //           // console.log('00', response.data)
          //           this.$store.commit('updateUser', response.data)
          //           this.$router.push('/perfil').catch(()=>{})
          //           this.$store.commit('closeModal')
          //         }
          //       })
          //       .catch(()=>{})
          //   })
        })
        .catch(err => {
          this.danger(err.message)
        })


        
    },

    // Reset Password -------
    async resetPassword () {
      await firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.okReset('Revisa tu correo')
        })
        .catch((error) => {
          const errorMessage = error.message
          this.danger(errorMessage)
        })
    },
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    okReset (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
    async ValidityEmail () {
        if (!this.validEmail(this.email)) {
          this.mensajeEmail = 'Algo está mal. Revisa tu correo electrónico.'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else {
          this.mensajeEmail = ''
          // this.messageTypeEmail = 'is-success'
        }
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>

<style scoped>

.title-header-section-create-account {
  margin-top: 73px;
  margin-left: 5%;
  width: 31%;
  margin: auto;
}

.title-header-section-create-account p {
  font-family: "Montserrat Bold";
  color: #000;
  font-size: 35px;
}

.form-login {
  margin-top: 70px;
}

.container-content-create-account {
  width: 85%;
}

form {
  width: 90%;
  margin: auto;
  max-width: 360px;
}

.label:not(:last-child) {
    margin-bottom: 0.3em !important;
}

.link-text-login {
  font-size: 12.5px;
}

.button-close {
  width: 90%;
  margin: auto;
  text-align: right;
}

@media only screen and (max-width: 890px) {

  .title-header-section-create-account p {
    font-size: 25px;
  }

  .title-header-section-create-account {
    width: 90%;
    margin: auto;
  }

  #sign_up .container-content-create-account {
    width: 90%;
    margin: auto;
  }

  .container-content-create-account {
    margin: auto;
  }
}

</style>
