import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {

  // async getPublicProfile (username) {
  //   return await apiClient.get(`/profiles/${username}`)
  // },

  async getAllProfiles (idToken) {
    return await apiClient.get('profiles',
    {
      headers: { idToken: idToken }
    })
  },

  async getAllMentors () {
    return await apiClient.get('profiles/mentors')
  },

  // async getAllProfiles () {
  //   return await apiClient.get('/profiles/')
  // },

  async getCurrentProfiles (idToken) {
    return await apiClient.get('profiles/current',
      {
        headers: { idToken: idToken }
      })
  },

  async createNewProfile (idToken, profile) {
    const response = await apiClient.post('profiles', profile,
      {
        headers: { idToken: idToken }
      })

    return response
  },

  async updateProfile (idToken, profile) {
    // console.log('updateProfileFront', profile)
    const response = await apiClient.post('profiles/current', profile,
      {
        headers: { idToken: idToken }
      })

    return response
  },


  async updateProfileAdmin (idToken, profileId, profile) {
     console.log('updateProfileFront', profile)
    const response = await apiClient.post(`profiles/${profileId}`, profile,
      {
        headers: { idToken: idToken }
      })

    return response
  },

  async deleteProfile (idToken, profileId) {
   const response = await apiClient.delete(`profiles/${profileId}`,
     {
       headers: { idToken: idToken }
     })

   return response
 },

  // async approveProfile (idToken) {
  //   console.log('approveProfile')
  //   const response = await apiClient.post('profiles/aprove', {},
  //     {
  //       headers: { idToken: idToken }
  //     })

  //   return response
  // }

}
