import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async NewClass (idToken, obj) {
    return await apiClient.post('class/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async GetClass () {
    return await apiClient.get('class/')
  },
  async GetCourseClasses (courseId) {
    return await apiClient.get(`class/course/${courseId}`)
  },
  async GetProfileClasses (profileId) {
    return await apiClient.get(`class/profile/${profileId}`)
  },
  async UpdateClass (idToken, obj, id_class) {
    return await apiClient.put(`class/${id_class}`, obj,
      {
        headers: { idToken: idToken }
      })
  },
  async DeleteClass(idToken, id_class) {
    return await apiClient.delete(`class/${id_class}`, {
      headers: { idToken: idToken }
    })
  }
}
