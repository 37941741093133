<template>
  <div class="cursos-panel">
    <div id="cursos" class="cursos">
      <div class="content-course">
        <span class="white montserrat-bold title-course">Cursos</span>
        <span class="white m-left montserrat-bold title-course"
          >activos</span
        >

        <p class="opensans white is-size-text-normal paragraph-text">
          It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.
        </p>

        <div
          v-for="(curso, index) in $store.getters.allCoursesFormated"
          :key="index"
          class="cursosContainer">
          <div
            v-if="curso.active"
            class="cursoBox"
          >
            <div  class="content-box-course">
              <div class="image-place-course">
                <img :src="curso.location.picture" />
              </div>

              <div class="center">
                <span class="montserrat-bold is-size-medium-text">{{
                  curso.name
                }}</span>

                <div>
                  <span class="montserrat-bold is-size-text-normal"
                    >Mentor:
                  </span>
                  <span class="montserrat is-size-text-normal">
                    {{ curso.mentor }}</span
                  >
                  <span class="montserrat-bold is-size-text-normal ml-3"
                    >Lugar:</span
                  >
                  <span class="montserrat is-size-text-normal">
                    {{ curso.location.name }}</span
                  >
                </div>

                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Horario:</p>
                  <span
                    class="montserrat mr-2 mobile-text"
                    v-for="(dia, index2) in curso.horario"
                    :key="index2"
                    > <b class="montserrat-extrabold">·</b> {{ dia }}
                  </span>
                </div>
                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Condiciones</p>
                  <span class="montserrat mr-2 mobile-text"> 
                    <p>{{ curso.conditions }} </p> 
                  </span>
                </div>
              </div>

              <div>
                <button
                  class="button-primary-orange-small"
                  @click.prevent="$store.state.user.credits > 0 ? modalReserva(curso) : $store.commit('openModal', 'addcredits')"
                >
                  Reservar clase
                </button>
                <button
                  class="button-primary-orange-small mleft-button"
                  @click.prevent="$store.state.user.credits > 0 ? newBono(curso) : $store.commit('openModal', 'addcredits')"
                >
                  Comprar Bono
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CursosPanel',
  methods: {
    // Reservar
    modalReserva (curso) {
      const reservation = {
        course: curso,
        with_bono: false
      }
      this.$store.commit('updateCurrentCourse', curso._id)
      this.$store.commit('updateReservation', reservation)
      this.$store.commit('incrementBookingStep')
      this.$store.commit('incrementBookingStep')
      this.$store.commit('openModalR', 'newbooking')
    },
    // NewBono
    newBono (curso) {
      const newBono = {course: curso}
      this.$store.commit('updateCurrentCourse', curso._id)
      this.$store.commit('updateBonoState', newBono)
      this.$store.commit('openModalR', 'newbono')
    },
  }
}
</script>

<style scoped>
.cursos-panel {
  margin-top: 65px;
}

/* COURSE */

.content-course {
  padding-top: 95px;
  width: 75%;
  margin: auto;
}

.cursosContainer {
  margin-top: 10px;
}

.paragraph-text {
  margin-top: 25px;
  max-width: 658px;
  line-height: 25px;
  opacity: 0.7;
  margin-bottom: 70px;
}

.title-course {
  font-size: 25px;
}

.cursos {
  width: 100%;
  background-color: #171616;
  padding-bottom: 100px;
}

.cursoBox {
  width: 100%;
  background-color: rgb(140, 140, 140, 0.19);
  color: #fafafa;
  margin-top: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.content-box-course {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.image-place-course {
  object-fit: cover;
  display: block;
}

.image-place-course img {
  min-width: 120px;
  max-width: 130px;
  border-radius: 2px;
}

.center {
  max-width: 40%;
  min-width: 40%;
  margin-left: 17px;
  margin-right: 17px;
}

.m-left {
  margin-left: 10px;
}

.mobile-text {
  font-size: 13px;
  opacity: 0.6;
}

.mleft-button {
  margin-left: 6px;
}

@media only screen and (max-width: 997px) {
  .content-course {
    padding-top: 80px;
    width: 90%;
  }


  .paragraph-text {
    max-width: 542px;
  }

  .content-box-course {
    width: 95%;
  }

  .center {
    max-width: 46%;
    min-width: 49%;
  }
}

@media only screen and (max-width: 928px) {
  
  .image-place-course {
    display: none;
  }

  .center {
    max-width: 64%;
    min-width: 64%;
  }

  .paragraph-text {
    max-width: 474px;
  }
}

@media only screen and (max-width: 894px) {
  .content-box-course {
    display: block;
  }

  .center {
    max-width: 90%;
  }

  .mleft-button {
    margin-left: 15px;
    margin-top: 15px;
  }

  .content-course {
    padding-top: 42px;
  }
}
</style>