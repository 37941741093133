<template>
  <div id="sign_up">

    <div class="header-modal">

      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold white title-modal">Nuevo Lugar</h3>
        
        <!-- BUTTON CLOSE -->
        <a @click="closeModal">
          <div class="">
            <svg class="button-close" width="18" height="18" viewBox="0 0 20 20" fill="none">
              <path d="M18 2L2 18" stroke="#fff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 2L18 18" stroke="#fff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
    </div>
    
    <!-- FORM EDIT COURSE -->
    <div
      class="form-create-course">
      <div
        class="principal-info-course">
        <h3
          class="montserrat-bold black">
          Información del lugar
        </h3>
      </div>
      <div
        class="info-course">
  
          <div class="container-form">

        <!-- Add new place of class -->
        <form
          class="">

          <!-- name place of class -->
          <b-field
            class="max-campos mt-2"
            label="Nombre del lugar"
            :message="mensajeNameLocation"
            :type="messageTypeNameLocation">
            
            <b-input
              v-model="NameLocation"
              v-on:focusout.native="ValidityNameLocation"
              v-on:focusin.native="clearValidityNameLocation"
              id="name"
              autocomplete="off">
            </b-input>
          </b-field>

          <!-- Ubication place of class -->

          <div
            class="max-campos is-flex mt-2">

            <b-field
              class=""
              label="Longitud"
              :message="mensajeLongitud"
              :type="messageTypeLongitud"
              >

              <b-input
                v-model="Longitud"
                v-on:focusout.native="ValidityLongitud"
                v-on:focusin.native="clearValidityLongitud"
                id="Longitud"
                autocomplete="off">
              </b-input>
            </b-field>

            <b-field
              class="ml-2"
              label="Latitud"
              :message="mensajeLatitud"
              :type="messageTypeLatitud">
              <b-input
                v-model="Latitud"
                v-on:focusout.native="ValidityLatitud"
                v-on:focusin.native="clearValidityLatitud"
                id="leatitudePlace"
                autocomplete="off">
              </b-input>
            </b-field>
          </div>

          <!-- description place of class -->
          <b-field
            class="max-campos mt-2"
            label="Descripción del lugar">

            <b-input
              v-model="Description"
              v-on:focusout.native="ValidityDescription"
              v-on:focusin.native="clearValidityDescription"
              id="description"
              type="textarea"
              maxlength="200"
              autocomplete="off"
            ></b-input>

          </b-field>


          

          <!-- Upload image  of the class place -->

          <div
            class="upload-image">

            <p @click="uploadImageModal"
              class="montserrat-medium mt-3 gray-ligth text-underline">
              Subir foto del lugar</p>

            <div
            class="container-button-upload mt-3">
              
            <div class="image-profile">
              <img
                :src="$store.state.picture"
                width=500
                height=230
              >
            </div>

              
            </div>
          </div>
        </form>

          </div>
      </div>
    </div>

    <div class="container-buttons">
      <button
        @click="closeModal"
        class="button-medium-stroke-black">Cancelar</button>
      <button
        @click="newLocation"
        class="button-primary-extra-medium ml-2">Guardar</button>
    </div>

  </div>


</template>

<script>

import LocationService from '@/services/LocationService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Media from '@/services/MediaService'

export default {
  name: 'NewLocation',
  components: {
  },
  data () {
    return {
      NameLocation: null,
      Description: null,
      Longitud: null,
      Latitud: null,

      mensajeNameLocation: '',
      mensajeDescription: '',
      mensajeLongitud: '',
      mensajeLatitud: '',

      messageTypeNameLocation: '',
      messageTypeDescription: '',
      messageTypeLongitud: '',
      messageTypeLatitud: '',

      valid: true,
      mensaje: '',
      loader: false,
    }
  },

  // METHODS
  methods: {

    // Upload image
    uploadImageModal () {
      this.$store.commit('openModal2', 'uploadimage')
    },

    // Close modal
    async closeModal () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await Media.deleteImageCloudinary(idToken, this.$store.state.picture_public_id)
      this.$store.commit('closeModal')
    },

    // New Location --------
    async newLocation () {
      if (this.NameLocation && this.Description && this.Longitud && this.Latitud) {
        this.valid = true
      }
      await this.ValidityNameLocation()
      await this.ValidityDescription()
      await this.ValidityLongitud()
      await this.ValidityLatitud()


      if (this.valid) {

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newLocation = await LocationService.NewLocation( idToken, {
          name: this.NameLocation,
          description: this.Description,
          latitud: this.Latitud,
          longitud: this.Longitud,
          picture: this.$store.state.picture,
          picture_public_id: this.$store.state.picture_public_id
        })

        if (newLocation.status === 201) {
          this.$store.commit('closeModal')
          this.$store.commit('updateLocations')
        }

      }
    },

    ValidityNameLocation () {
      if (!this.NameLocation) {
        this.mensajeNameLocation = 'Cuál es el nombre del lugar?'
        this.messageTypeNameLocation = 'is-primary'
        this.valid = false
      } else {
        this.mensajeNameLocation = ''
      }
    },

    ValidityDescription () {
      if (!this.Description) {
        this.mensajeDescription = 'Cuál es la descripción del lugar?'
        this.messageTypeDescription = 'is-primary'
        this.valid = false
      } else {
        this.mensajeDescription = ''
      }
    },

    ValidityLongitud () {
      if (!this.Longitud) {
        this.mensajeLongitud = 'Cuál es la longitud?'
        this.messageTypeLongitud = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLongitud = ''
      }
    },

    ValidityLatitud () {
      if (!this.Latitud) {
        this.mensajeLatitud = 'Cuál es la latitud?'
        this.messageTypeLoatitude = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLatitud = ''
      }
    },
    

    clearValidityNameLocation () {
      this.messageTypeNameLocation = ''
      this.mensajeNameLocation = ''
    },

    clearValidityDescription () {
      this.messageTypeDescription = ''
      this.mensajeDescription = ''
    },

    clearValidityLongitud () {
      this.messageTypeLongitud = ''
      this.mensajeLongitud = ''
    },

    clearValidityLatitud () {
      this.messageTypeLatitud = ''
      this.mensajeLatitud = ''
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  }
}


</script>


<style scoped>

.header-modal {
  width: 100%;
  background-color: #3C3C3C;
  height: 70px;
}

.max-campos {
  max-width: 430px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 16px;
}

.form-create-course {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 50px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.principal-info-course {
  width: 90%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ECECEC;
}

.status-course {
  text-align: right;
}

.status-course p {
  font-size: 14px;
}

.small-text {
  font-size: 12px;
}

.info-course {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.container-form {
  display: flex;
  width: 80%;
  margin-top: 17px;
  justify-content: space-between;
}

.letf {
  max-width: 410px;
}

.name-course {
  min-width: 270px;
}

.date-course {
  display: flex;
}

.end-date {
  margin-left: 10px;
}

.max-student {
  margin-left: 7px;
}

.pricing-students {
  display: flex;
}

.mentor-place {
  display: flex;
}

.place {
  margin-left: 10px;
}

.container-buttons {
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 25px;
  padding-bottom: 45px;
  
}

.image-profile img {
  object-fit: cover;
}

.upload-image p {
  font-size: 15px;
  color: #727272;
}

.upload-image p:active, .upload-image p:focus, .upload-image p:hover {
  color: #F29713;
}

@media only screen and (max-width: 1120px) {
  .letf {
    max-width: 390px;
    margin-right: 10px;
  }

  .rigth {
    min-width: 239px;
  }

  .container-form {
    width: 100%;
  }
}


@media only screen and (max-width: 871px) {
  .container-form {
    display: block;
    width: 90%;
    margin: auto;
  }

  .right {
    display: flex;
    margin-top: 8px;
  }

  .level-course {
    margin-left: 10px;
  }

}

@media only screen and (max-width: 607px) {

  .form-create-course {
    width: 85%;
  }
}


@media only screen and (max-width: 513px) {

  .container-header-modal {
    width: 90%;
  }

  .principal-info-course h3 {
    font-size: 14px;
  }

  .container-header-modal h3 {
    font-size: 16px;
  }

  .header-modal svg {
    width: 15px;
    height: 15px;
  }

  .container-form {
    width: 100%;
  } 

  .form-create-course {
    width: 95%;
  }

  .button-close {
    width: 18px;
    height: 18px;
  }


  .no-mobile {
    display: none;
  }

  .date-course {
    display: block;
  }

  .end-date {
    margin-left: 0px;
  }
  
  .max-student {
    margin-left: 0px; 
  }

  .pricing-students {
    display: block;
    margin-top: 12px;
  }

  .mentor-place {
    
    margin-top: 12px;
  }

  .place {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .form-create-course {
    width: 93%;
    margin-top: 15px;
  }

  .header-modal {
    height: 60px;
  }

  .letf {
    width: 100%;
    margin-right: 0px;
  }

  .container-buttons {
    width: 90%;
    padding-bottom: 30px;
    margin-top: 20px;
  }

}
</style>
