<template>
  <div id="course">
    <div class="container-course">
      <p class="montserrat-medium black title-step">2. Elige un curso</p>

      <div class="list-courses">
        <div
          v-for="(course, index) in allCoursesForLevel"
          :key="index"
          class="content-course"
          v-bind:class="{
            bookingSelection:
              $store.state.reservationState.course &&
              $store.state.reservationState.course == course,
          }"
          v-on:click="saveCourseSelection(course)"
        >
          <!-- TITLE COURSE -->
          <p class="montserrat-bold black title-course">
            {{ course.name }}
          </p>

          <div class="info-complementary">
            <!-- PRICING -->
            <div class="is-flex pricing-text">
              <span class="opensans gray-medium"> Precio:</span>
              <span class="gray-medium OpenSans-SemiBold">
                {{ course.classprice }}Є</span
              >
            </div>

            <!-- DATE -->
            <div class="container-date is-flex">
              <!-- DATE START-->
              <div class="is-flex date-text">
                <span class="opensans gray-medium"> Inicio:</span>
                <span
                  v-if="course.startcourse != null"
                  class="gray-medium OpenSans-SemiBold ml-2"
                >
                  {{ course.startcourse.split("T")[0] }}</span
                >
              </div>

              <!-- DATE END-->
              <div class="is-flex ml-3 date-text">
                <span class="opensans gray-medium"> Fin:</span>
                <span
                  v-if="course.endcourse != null"
                  class="gray-medium OpenSans-SemiBold ml-2"
                >
                  {{ course.endcourse.split("T")[0] }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- COURSE -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ChooseCourse',
    data () {
      return {
      allCoursesForLevel: this.$store.getters.coursesForLevel(this.$store.state.reservationState.level)
    }
    },
    methods: {
      saveCourseSelection(selectedCourse) {
        let reservationState = this.$store.state.reservationState
        reservationState.course = selectedCourse
        this.$store.commit('updateCurrentCourse', selectedCourse._id)
        this.$store.commit('updateReservation', reservationState)
        this.$store.commit('incrementBookingStep')
      }
  }
}
</script>

<style scoped>
.title-step {
  font-size: 15px;
  margin-top: 60px;
}

.list-courses {
  margin: auto;
  margin-top: 25px;
}

.content-course {
  background-color: #f5f3f3;
  width: 98%;
  max-width: 360px;
  border-radius: 2px;
  margin-top: 17px;
  padding-top: 10px;
  padding-bottom: 6px;
  border: none;
  color: #000;
  font-family: "Montserrat SemiBold";
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
}

.content-course:hover {
  background-color: #fbdfb6;
}

.title-course {
  font-size: 15px;
}

.pricing-text {
  font-size: 14px;
}

.date-text {
  font-size: 14px;
}
</style>