import { render, staticRenderFns } from "./type-student.vue?vue&type=template&id=c11d39fc&scoped=true&"
import script from "./type-student.vue?vue&type=script&lang=js&"
export * from "./type-student.vue?vue&type=script&lang=js&"
import style0 from "./type-student.vue?vue&type=style&index=0&id=c11d39fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c11d39fc",
  null
  
)

export default component.exports