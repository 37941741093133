var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"level"}},[_c('div',{staticClass:"container-level"},[_c('p',{staticClass:"montserrat-medium black title-step"},[_vm._v("1. Elige un bono")]),(_vm.$store.getters.currentCourse.classprice3)?_c('div',{staticClass:"container-buttons-level"},[_c('button',{staticClass:"button-level",class:{
          bookingSelection:
            _vm.$store.state.bonoState.level &&
            _vm.$store.state.bonoState.level === 3,
        },on:{"click":function($event){return _vm.saveLevelSelection(3, _vm.$store.getters.currentCourse.classprice3)}}},[_vm._v(" "+_vm._s('3 Clases (' + _vm.$store.getters.currentCourse.classprice3 + '€)')+" ")])]):_vm._e(),(_vm.$store.getters.currentCourse.classprice5)?_c('div',{staticClass:"container-buttons-level"},[_c('button',{staticClass:"button-level",class:{
          bookingSelection:
            _vm.$store.state.bonoState.level &&
            _vm.$store.state.bonoState.level === 5,
        },on:{"click":function($event){return _vm.saveLevelSelection(5, _vm.$store.getters.currentCourse.classprice5)}}},[_vm._v(" "+_vm._s('5 Clases (' + _vm.$store.getters.currentCourse.classprice5 + '€)')+" ")])]):_vm._e(),(_vm.$store.getters.currentCourse.classprice10)?_c('div',{staticClass:"container-buttons-level"},[_c('button',{staticClass:"button-level",class:{
          bookingSelection:
            _vm.$store.state.bonoState.level &&
            _vm.$store.state.bonoState.level === 10,
        },on:{"click":function($event){return _vm.saveLevelSelection(10, _vm.$store.getters.currentCourse.classprice10)}}},[_vm._v(" "+_vm._s('10 Clases (' + _vm.$store.getters.currentCourse.classprice10 + '€)')+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }