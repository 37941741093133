import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MisPerfiles from '../views/panel/MisPerfiles.vue'
import CursosActivos from '../views/panel/CursosActivos.vue'
import MisBonos from '../views/panel/MisBonos.vue'
import Perfil from '../views/panel/detalle/Perfil.vue'
import Clases from '../views/panel/detalle/Clases.vue'

import AdminEstudiantes from '../views/admin/Estudiantes.vue'
import AdminCursos from '../views/admin/Cursos.vue'
import AdminCalendario from '../views/admin/Calendario.vue'
import AdminPagos from '../views/admin/Pagos.vue'
import AdminConfiguracion from '../views/admin/Configuracion.vue'
import AdminRecargas from '../views/admin/detalle/Recargas.vue'
import AdminTypeOfStudent from '../views/admin/detalle/TypeOfStudent.vue'
import AdminLugares from '../views/admin/Lugares.vue'

import AdminEstudiante from '../views/admin/detalle/Estudiante.vue'
import AdminCurso from '../views/admin/detalle/Curso.vue'
import AdminEstudianteClases from '../views/admin/detalle/EstudianteClases.vue'
import AdminLocation from '../views/admin/detalle/Location.vue'

import SignupMentor from '../views/mentor/signupMentor.vue'
import MentorCalendario from '../views/mentor/Calendario.vue'
import MentorPerfil from '../views/mentor/Perfil.vue'

import CheckoutSuccess from '../components/modals/pay-success.vue'
import CheckoutCancel from '../components/modals/pay-rejected.vue'

import store from '../store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/registro-profesor',
    name: 'SignupMentor',
    component: SignupMentor
  },
  {
    path: '/checkout/success',
    name: 'CheckoutSuccess',
    component: CheckoutSuccess
  },
  {
    path: '/checkout/cancel',
    name: 'CheckoutCancel',
    component: CheckoutCancel
  },
  {
    path: '/panel/mis-perfiles',
    name: 'MisPerfiles',
    component: MisPerfiles,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/panel/cursos-activos',
    name: 'CursosActivos',
    component: CursosActivos,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/panel/mis-bonos',
    name: 'MisBonos',
    component: MisBonos,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/panel/:profile_id/perfil',
    name: 'Perfil',
    component: Perfil,
    meta: {
      hasNavbar: false,
      onlyLogged: true,
      hasNavbarProfile: true
    }
  },
  {
    path: '/panel/:profile_id/clases',
    name: 'Clases',
    component: Clases,
    meta: {
      hasNavbar: false,
      onlyLogged: true,
      hasNavbarProfile: true
    }
  },
  {
    path: '/admin/estudiantes',
    name: 'AdminEstudiantes',
    component: AdminEstudiantes,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/cursos',
    name: 'AdminCursos',
    component: AdminCursos,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/calendario',
    name: 'AdminCalendario',
    component: AdminCalendario,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/pagos',
    name: 'AdminPagos',
    component: AdminPagos,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/configuracion',
    name: 'AdminConfiguracion',
    component: AdminConfiguracion,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/configuracion/recarga/:id_recarga',
    name: 'AdminConfiguracionRecarga',
    component: AdminRecargas,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/configuracion/typeofstudent/:id_typeofstudent',
    name: 'AdminConfiguracionTypeOfStudent',
    component: AdminTypeOfStudent,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/lugares',
    name: 'AdminLugares',
    component: AdminLugares,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/admin/estudiantes/:id_estudiante',
    name: 'AdminEstudiante',
    component: AdminEstudiante,
    meta: {
      hasNavbar: false,
      onlyLogged: true,
      hasNavbarEstudiante: true
    }
  },
  {
    path: '/admin/estudiantes/:id_estudiante/clases',
    name: 'AdminEstudianteClases',
    component: AdminEstudianteClases,
    meta: {
      hasNavbar: false,
      onlyLogged: true,
      hasNavbarEstudiante: true
    }
  },
  {
    path: '/admin/cursos/:id_curso',
    name: 'AdminCurso',
    component: AdminCurso,
    meta: {
      hasNavbar: false,
      onlyLogged: true,
      hasNavbarCurso: true
    }
  },
  {
    path: '/admin/lugares/:id_location',
    name: 'AdminLocation',
    component: AdminLocation,
    meta: {
      hasNavbar: false,
      onlyLogged: true,
      hasNavbarLocation: true
    }
  },
  {
    path: '/mentor/:profile_id/calendario',
    name: 'MentorCalendario',
    component: MentorCalendario,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
  {
    path: '/mentor/:profile_id/perfil',
    name: 'MentorPerfil',
    component: MentorPerfil,
    meta: {
      hasNavbar: true,
      onlyLogged: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => { // to: ruta actual, from: ruta anterior

  // NavBar
  if (to.meta.hasNavbar) {
    store.commit('navbarHandler', true)
  } else {
    store.commit('navbarHandler', false)
  }


  // NavBarProfile
  if (to.meta.hasNavbarProfile) {
    store.commit('navbarProfileHandler', true)
  } else {
    store.commit('navbarProfileHandler', false)
  }

  // NavBarAdminEstudiante
  if (to.meta.hasNavbarEstudiante) {
    store.commit('navbarAdminEstudianteHandler', true)
  } else {
    store.commit('navbarAdminEstudianteHandler', false)
  }


  // NavBarAdminCurso
  if (to.meta.hasNavbarCurso) {
    store.commit('navbarAdminCursoHandler', true)
  } else {
    store.commit('navbarAdminCursoHandler', false)
  }

  // NavBarAdminLocation
  if (to.meta.hasNavbarLocation) {
    store.commit('navbarAdminLocationHandler', true)
  } else {
    store.commit('navbarAdminLocationHandler', false)
  }


  // Redirect when you should access a url
  if (to.meta.onlyLogged && store.state.authUser === null) {
    await firebase.auth().onAuthStateChanged(async authUser => {
      if (authUser) {
        next()
      } else {
        next({ name: 'Home' })
      }
    })
  } else {
    next()
  }

})

export default router
