<template>
  <div id="panel-lugares">
    
    <div class="container-lugares">

      <!-- LIST PLACES-->
      
      <div
        v-for="(location, idx) in $store.state.locations"
        :key="idx"
        class="list-places-container"
      >
        <!-- CARD PLACE-->

          <div class="container-places">
            <div class="content-place">
              <div class="container-image">
                <div>
                  <img
                    class="picture-place"
                    :src="location.picture"
                    width="40">
                </div>
                
                <div class="no-desktop name-place montserrat-bold black">
                  {{location.name}}
                </div>
              </div>
              
              <div class="info-complementary-place">
                <div class="no-mobile name-place montserrat-bold black">
                  {{location.name}}
                </div>

                <div class="buttons-place">
                  <button
                    @click="goToLocation(location._id)"
                    class="button-medium-stroke">
                    Editar</button>
                  <button
                    @click="deleteLocation(location._id)"
                    class="button-medium-stroke ml-3">
                    Borrar</button>
                </div>
              </div>
            </div>
            
          </div>

        </div>

        <div class="new_profile_card_container">
          <div
            class="new_place_card  black"
            @click.prevent="newPlace"
          >
            <p class="montserrat-semibold">+ Agregar nuevo lugar</p>
          </div>
        </div>

    </div>



  </div>
</template>

<script>
import LocationService from '@/services/LocationService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'AdminEstudiantes',
  components: {
  },
  data () {
    return {
      allProfiles: this.$store.state.allProfiles,
      selectedProfilesType: null,
      selectedProfilesStatus: null,
      selectedProfilesSearch: null,
      filterTypeUser: 'estudiante',
      filterStatus: null,
      searchBox: null
    }
  },

  mounted () {
    this.filterProfiles(this.filterTypeUser, this.filterStatus)
    this.searchProfiles ()
    this.$store.commit('clearCurrentLocation')
  },

  watch: {
    filterTypeUser: function () {
      this.filterProfiles(this.filterTypeUser, this.filterStatus)
      this.searchProfiles ()
    },

    filterStatus: function () {
      this.filterProfiles(this.filterTypeUser, this.filterStatus)
      this.searchProfiles ()
    },

    searchBox: function () {
      this.searchProfiles ()
    }
  },

  methods: {
    // Go to profile selected
    goToLocation (id) {
      this.$router.push(`/admin/lugares/${id}`)
    },

    // DELETE location
    async deleteLocation (id_location) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const response = await LocationService.DeleteLocation(idToken, id_location)

      if (response.status == 200) {
        this.success('Lugar eliminado')
        this.$store.commit('updateLocations')
      } else if (response.status == 203) {
        this.danger(response.data.msg)
      }

    },

    // New Place
    newPlace () {
      this.$store.commit('openModal', 'newlocation')
    },

    // Filter
    filterProfiles (filterTypeUser, filterStatus) {

      if (filterTypeUser === 'estudiante') {
        let estudianteProfiles = this.allProfiles.filter( profile => !profile.accountAdmin || !profile.isMentor)
        this.selectedProfilesType = estudianteProfiles
      } else if (filterTypeUser === 'profesor') {
        let profesorProfiles =  this.allProfiles.filter( profile => profile.isMentor)
        this.selectedProfilesType = profesorProfiles
      } else if (filterTypeUser === 'tutor') {
        let tutorProfiles = this.allProfiles.filter( profile => profile.accountAdmin && !profile.isMentor)
        this.selectedProfilesType = tutorProfiles
      }

      if (filterStatus === 'active') {
        let estudianteProfiles = this.selectedProfilesType.filter( profile => profile.status === 'active')
        this.selectedProfilesStatus = estudianteProfiles
      } else if (filterStatus === 'inactive') {
        let profesorProfiles =  this.selectedProfilesType.filter( profile => profile.status === 'inactive')
        this.selectedProfilesStatus = profesorProfiles
      } else if (filterStatus === 'revision') {
        let tutorProfiles = this.selectedProfilesType.filter( profile => profile.status === 'revision')
        this.selectedProfilesStatus = tutorProfiles
      } else if (filterStatus === null || filterStatus === 'all') {
        this.selectedProfilesStatus = this.selectedProfilesType
      }
    },

    // Search
    searchProfiles () {
      if (this.searchBox === '' || this.searchBox === null) {
        this.selectedProfilesSearch = this.selectedProfilesStatus
      } else {
        let searchString = this.searchBox.toLowerCase()
        const searchedProfiles = this.selectedProfilesStatus.filter(profile => {
          if (
            profile.firstname === undefined ||
            profile.lastname === undefined
            ) {
            return false
          } else {
            return (
              profile.firstname.toLowerCase().includes(searchString) ||
              profile.lastname.toLowerCase().includes(searchString)
            )
          }
        })

        this.selectedProfilesSearch = searchedProfiles
      }
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  }
}
</script>

<style scoped>

#panel-lugares {
  background-color: #FAFAFA;
  height: 100vh;
  padding-bottom: 30px;
  padding-top: 94px;
}

.container-lugares {
  width: 95%;
  max-width: 882px;
  background-color: #fff;
  border: 1px solid #EDEDED;
  margin: auto;
  padding-bottom: 30px;
}

.list-places-container {
  width: 90%;
  margin: auto;
  margin-top: 10px;
}

.content-place {
  background-color: #F5F3F3;
  display: flex;
  align-items: center;
  padding: 7px 27px 7px 20px;
  border-radius: 3px;
}

.picture-place {
  border-radius: 100px;
  margin-top: 5px;
}

.info-complementary-place {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: 20px;
  align-items: center;
}

.new_place_card {
  width: 100%;
  margin: auto;
  margin-top: 12px;
  margin-left: 5.5%;
  min-height: 57px;
  border: 1.9px dashed rgb(162, 162, 162);
  border-radius: 2px;
  padding: 15px 27px 7px 20px;
}

.new_place_card p {
  font-size: 13px;
}

.button-medium-stroke {
  width: 110px !important;
}

.container-lugares {
  padding-top: 25px;
}

.no-desktop {
  display: none;
}

@media only screen and (max-width: 578px) {
  .no-desktop {
    display: block;
  }

  .no-mobile {
    display: none;
  }

  .content-place {
    display: block;
  }

  .container-image {
    display: flex;
    align-items: center;
  }

  .name-place {
    margin-left: 20px;
  }

  .info-complementary-place {
    width: 100%;
    margin-left: 0px;
    margin-top: 12px;
  }
}

</style>