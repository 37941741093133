import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async getBlocksByWeek (professionaId, week) {
    return await apiClient.get(`/calendars/${professionaId}/week/${week}`)
  },

  async updateCalendar (idToken, mondayBlocks, tuesdayBlocks, wednesdayBlocks, thursdayBlocks, fridayBlocks, saturdayBlocks, sundayBlocks, calendarId) {
    return await apiClient.post(`/calendars/${calendarId}`,
      {
        1: mondayBlocks,
        2: tuesdayBlocks,
        3: wednesdayBlocks,
        4: thursdayBlocks,
        5: fridayBlocks,
        6: saturdayBlocks,
        7: sundayBlocks
      },
      {
        headers: { idToken: idToken }
      })
  },
  async getCalendar (idToken, calendarId) {
    return await apiClient.get(`/calendars/${calendarId}`,
      {
        headers: { idToken: idToken }
      })
  }
}
