<template>
  <!-- <div id="navbar"> -->
  <b-navbar id="navbar" class="is-align-items-center">
          <template #brand>
              <b-navbar-item tag="router-link" :to="{ path: '/' }">
                  <img
                      class="logo-kontraola"
                      src="@/assets/images/navBar/logo_kontraola_white.svg"
                      alt="logo kontraola panel_container"
                  >
              </b-navbar-item>


          </template>

          <template #start>
          
          </template>

          <template #end>
            <div v-if="!mobile" id="nav-admin-general" class="navBar-admin montserrat white">
              <router-link class="" :to="{ name: 'AdminEstudiantes'}">Perfiles</router-link> 
              <router-link :to="{ name: 'AdminCursos'}">Cursos</router-link>
              <router-link :to="{ name: 'AdminCalendario'}">Calendario</router-link>
              <router-link :to="{ name: 'AdminPagos'}">Pagos</router-link>
              <router-link :to="{ name: 'AdminLugares'}">Lugares</router-link>
              <router-link :to="{ name: 'AdminConfiguracion'}">Configuración</router-link>
            </div>
            <div class="container-end">
              <img  
                    @click="showMenu"                
                    class="is-rounded image-avatar-panel"
                    :src="$store.getters.avatar"
                    width=45 >
                  <img
                    class="arrow-avatar-nav-bar is-align-self-flex-end"
                    src="@/assets/images/navBar/arrow.svg"
                    alt="">
            </div>
            <!-- avatar options box -->
            <div id="menu_logout_mobile" class="disp-none btn-logout">
              <div v-if="mobile" @click="showMenu" class="menu-item-mobile">
                  <router-link class="" :to="{ name: 'AdminEstudiantes'}">Perfiles</router-link>
              </div> 
              
              <div v-if="mobile" @click="showMenu" class="menu-item-mobile">
                <router-link :to="{ name: 'AdminCursos'}">Cursos</router-link>
              </div>
              
              <div v-if="mobile" @click="showMenu" class="menu-item-mobile">
                <router-link :to="{ name: 'AdminCalendario'}">Calendario</router-link>
              </div>
              <div v-if="mobile" @click="showMenu" class="menu-item-mobile">
                <router-link :to="{ name: 'AdminPagos'}">Pagos</router-link>
              </div>
              <div v-if="mobile" @click="showMenu" class="menu-item-mobile">
                <router-link :to="{ name: 'AdminLugares'}">Lugares</router-link>
              </div>
              <div v-if="mobile" @click="showMenu" class="menu-item-mobile">
                <router-link :to="{ name: 'AdminConfiguracion'}">Configuración</router-link>
              </div>

              <div v-if="mobile" class="line-gray pb-4 pt-3"></div>

              <div class="is-flex is-align-items-center">
                <img class="mr-2 img-logout" src="@/assets/images/navBar/logout.svg" alt="logout">
                <a @click.prevent="logout" class="button is-light text-basier">
                    Salir
                </a>
              </div>
            </div>
          </template>


      </b-navbar>

</template>

<script>
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'

  export default {
    name: 'NavBar',
    components: {
    },
    data () {
      return {
        mobile: false
      }
    },
    mounted () {
      const browser_width = window.innerWidth
      if (browser_width > 800) {
        this.mobile = false
      } else {
        this.mobile = true
      }
    },


    methods: {
      // Logout
      async logout() {
        // console.log('oio')
        await firebase.auth().signOut()
        this.$router.push('/').catch(()=>{})
      },
      showMenu () {
        document.getElementById('menu_logout_mobile').classList.toggle('disp-none')
      },
      // New Profile
      newprofile () {
        this.$store.commit('openModal', 'newprofile')
      },

    }

  }
</script>

<style lang="scss" scoped>

.menu-item-mobile {
  margin-bottom: 30px;
}

.img-logout {
  width: 24px;
}

#navbar {
  display: flex;
  padding: 0 25px;
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
}

#nav-admin-general {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#chance_password .label,
#chance_password input::placeholder {
  color: #555!important;
}

.navBar-admin {
  width: 604px;
  display: flex;
  justify-content: space-between;
 
}

#nav-admin-general a {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 13px;
  font-family: "Montserrat";
}

#nav-admin-general a.router-link-exact-active {
  font-family: "Montserrat Bold";
  font-size: 15px !important;
  color: rgba(255, 255, 255, 1) !important;
}

.image-avatar-panel {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 30px;
}

.circle-available {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00D6CB;
  position: absolute;
  top: 18px;
  right: 29px;
  /* border: 2px solid; */
}

.circle-review {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff9100;
  position: absolute;
  top: 10px;
  right: 25px;
}

.circle-disable {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #FF1515;
  position: absolute;
  top: 4px;
  right: 27px;
  /* border: 2px solid; */
}

.line-gray {
  border-top: 1px solid #ECECEC;
  height: 3px;
  width: 80%;
}
.arrow-avatar-nav-bar {
  width: 8px;
  height: 7px;
}
.resetPasswordModal {
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
}

.menu-my-profile {
    visibility: hidden;
}

.button {
    background-color: #fff !important;
}

.item-menu img {
    width: 20px;
}

.logout {
  float: right;
  margin-right: -9px;
  margin-top: -4px;
  padding-left: 26px;
  width: 40px;
}
.disp-none {
  display: none!important;
}

.btn-logout {
  top: 57px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 54px;
  position: absolute;
  right:15px;
  width: 257px;
  background-color: #fff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.btn-logout a {
  background-color: #fff;
  color: #0D1239;
  font-size: 13.5px;
}

.title-header-section-create-account {
  margin-top: 73px;
  margin-left: 5%;
}

.form-login {
  margin-top: 70px;
}

.container-content-create-account {
  width: 85%;
}
.position-fixed {
  position: fixed!important;
}
.position-abosolute {
  position: absolute!important;
}
form {
  width: 90%;
  margin: auto;
  max-width: 360px;
}

.label:not(:last-child) {
  margin-bottom: 0.3em !important;
}

.container-end {
  height: 56px;
  padding-top: 26px;
}


@media only screen and (max-width: 1023px) {

  #nav-admin-general {
    padding: 0px 15px 0px ;
    
  }

  .navbar-menu {
    padding-bottom: 0px !important; 
  }

  .container-end {
    padding-top: 8px;
  }
}

@media only screen and (max-width: 1009px) {

  #nav-admin-general {
    padding: 0px !important;
  }

  .navBar-admin {
    width: 450px;
    margin-right: 16px;
    align-items: center;
  }

  .container-end {
    padding-top: 12px;
  }

  .navbar-menu {
    padding-bottom: 0px;
  }

  .navbar-menu {
    padding-bottom: 0px;
  }
}



/*
@media (min-width: 1024px) {
  .btn-logout {
    top: 29px;
  }
}
@media (max-width: 1024px) {

  .visiting-profile {
    width: 100%!important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* position: absolute!important; 
  }
  .visiting-profile-w {
    width: 100%!important;
  }
}*/

</style>