<template>
  <div class="loading-app is-flex is-flex-direction-column">
    <img src="@/assets/loader_img.svg" alt="loading">
    <progress-bar
      style="width: 45%;"
      bar-color="#7AD398"
      :val="valLoading"
      :text="valLoading.toString() + '%'"
      :title="valLoading"
      :max="valueLoadingMax"
      bg-color="#D3EEDC"
      :font-size="14"
      text-fg-color="#7AD398"
      :size="6"
      :bar-border-radius="11"
    ></progress-bar>
  </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress'

export default {
  components: {
    ProgressBar
  },
  data () {
    return {
      valLoading: 0,
      valueLoadingMax: 100
    }
  },
  watch: {
    valLoading: function () {
      setTimeout(() => {
        if (this.valLoading < this.valueLoadingMax) {
          this.valLoading++
        }
      }, 100)
    }
  },
  created () {
    this.valLoading++
  }

}
</script>

<style scoped>
.loading-app {
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 66px;
}
</style>
