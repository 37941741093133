<template>
  <div id="refill-admin">
    <div class="header-modal">
      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold black">Update Recarga</h3>
      </div>
    </div>

    <!-- FORM -->
    <div class="form-create-refill">
      <div class="container-form">
        <!-- ADD NEW REFILL -->
        <form class="mt-6">

          <div class="max-campos mt-2">
            
            <!-- AMOUNT NEW REFILL -->
            <b-field
              class=""
              label="Valor a recargar"
            >
              <b-input
                id=""
                placeholder="EUR"
                type="number"
                autocomplete="off"
                v-model="amount"
              >
              </b-input>
            </b-field>
            
            <!-- PERCENTAGE ADD AMOUNT -->
            <b-field
              class="mt-2"
              label="Porcentaje agregado por valor"
            >
              <b-input
                id=""
                placeholder="%"
                type="number"
                autocomplete="off"
                v-model="discount"
              >
              </b-input>
            </b-field>
          </div>
        </form>

        <!-- button save information place of class -->

        <div class="container-buttons">
          <button
            @click="backToConfiguration"
            class="button-medium-stroke-black">Cancelar</button>
          <button
            @click="updateRecarga"
            class="button-primary-extra-medium ml-2">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configurationService from "../../../services/ConfigurationService";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
    name: 'UpdateRecarga',
    data () {
      return {
        amount: 0,
        discount: 0,
      }
    },
    async created () {
      configurationService.getRecarga(this.$route.params.id_recarga)
      .then(response => {
        if (response.status === 200 || response.status === 304) {
          this.amount = response.data[0].amount
          this.discount = response.data[0].discount
        }
      }).catch(()=>{})
    },
    //METHODS
    methods: {
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
    backToConfiguration() {
      this.$router.push('/admin/configuracion').catch(()=>{})
    },
    async updateRecarga() {
      if (this.amount && this.discount) {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const updateRecarga = await configurationService.updateRecarga(idToken, {
          amount: this.amount,
          discount: this.discount
        }, this.$route.params.id_recarga)
        if (updateRecarga.status === 200) {
          this.$store.commit('updateDiscounts')
          this.success("Guardado")
          this.$router.push('/admin/configuracion')
        }
      }
    }
  }
};
</script>

<style scoped>
.header-modal {
  width: 100%;
  background-color: #efefef;
  height: 72px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.max-campos {
  max-width: 430px;
}
</style>