<template>
  <div>
    <div class="space_65">
    </div>
    <kalendar :configuration="calendar_settings" :events.sync="events">
      <div slot="created-card" slot-scope="{ event_information }" class="details-card" @click.prevent="classPopup($event)" v-bind:id="event_information.data.classID">
        <!-- Day -->
        <h3 class="montserrat-semibold black">{{event_information.start_time | toDay}}</h3>
        <!-- HOUR OF CLASS -->
        <h3 class="montserrat-semibold black">{{event_information.start_time | toHours}} - {{event_information.end_time | toHours}}</h3>

        <!-- COURSE CLASS -->
        <p class="montserrat-medium black is-mini-small-text">{{event_information.data.courseName}}</p>
        
        <!-- LEVEL COURSE -->
        <div class="montserrat is-mini-small-text">{{event_information.data.level}}</div>

        <!-- MENTOR -->
        <div class="montserrat is-mini-small-text">{{event_information.data.mentor.firstname}} {{event_information.data.mentor.lastname}}</div>

        <!-- STUDENTS INSCRIBED -->
        <p class="montserrat is-mini-small-text">{{event_information.data.numberOfStudents}} / {{event_information.data.maxStudents}} estudiantes</p>

      </div>
    </kalendar>
  </div>
</template>

<script>
import PortalVue from "portal-vue";
import Vue from 'vue'
import { Kalendar } from 'kalendar-vue';
import dayjs from 'dayjs'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import ClassService from '@/services/ClassService'

let localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.locale('es')

Vue.use(PortalVue);
Vue.use(dayjs);

export default {
  name: 'CalendarMentor',
  components: {
    Kalendar
  },
  data: () => ({
    events: [],
    calendar_settings: {
      style: 'material_design',
      cell_height: 10,
      scrollToNow: true,
      current_day: new Date(),
      read_only: true,
      day_starts_at: 0,
      day_ends_at: 24,
      overlap: true,
      hide_days: [],
      past_event_creation: true
    }
  }),
  created() {
    this.$store.commit('updateCurrentProfile', window.location.pathname.split('/')[2])

    let allClasses = this.$store.state.allClasses
    ClassService.GetClass()
      .then(response => {
      if (response.status === 200) allClasses = response.data
      this.$store.commit('updateAllClasses', response.data)  
      allClasses.forEach(clase => {
        if (this.$store.getters.currentProfile.status === "active" && this.$store.state.currentProfileId === clase.mentor._id) {
          let course = this.$store.getters.getCourse(clase.course_id)
          let from = dayjs(clase.startclass).add(dayjs().utcOffset(), 'minutes').toJSON()
          let end = dayjs(clase.endclass).add(dayjs().utcOffset(), 'minutes').toJSON()

          let event = {
            data: {
              classID: clase._id,
              courseName: course.name,
              level: course.skilllevel,
              maxStudents: clase.maxstudents,
              numberOfStudents: clase.bookings.length,
              mentor: clase.mentor
            },
            from: from,
            to: end
          }
          this.$kalendar.addNewEvent(event)
          }
      })  
    })
  },


  filters: {
    toHours: (date) => {
      return dayjs(date).format('h:mm A')
    },
    toDay: (date) => {
      let weekDayString = dayjs(date).format('dddd, ')
      let dayString = dayjs(date).format('D.')
      let monthString = dayjs(date).format('MMMM')
      return weekDayString.charAt(0).toUpperCase() + weekDayString.slice(1) + dayString + " " + monthString.charAt(0).toUpperCase() + monthString.slice(1)
    }
  },
  methods: {
    classPopup(e) {
      this.$store.commit('selectedClass', e.currentTarget.id)
      this.$store.commit('openModal', 'detailclass')
    }
  }
}
</script>

<style>
.created-event, .number-date, .hour-indicator-line > span.line:before, .hour-indicator-line > span.line {
  background-color: #EDE9E9 !important;
  color: #393939 !important;
}

.kalendar-wrapper.gstyle .created-event {
  width: 100% !important;
}

.created-event>.details-card {
  height: 100%;
}

.today .number-date {
  background-color: #F29713 !important;
}

</style>
