import Vue from 'vue'
import Vuex from 'vuex'
import ConfigurationService from '@/services/ConfigurationService'
import LocationService from '@/services/LocationService'
import ClassService from '@/services/ClassService'
import CourseService from '@/services/CourseService'
import ProfileService from '@/services/ProfileService'
import UserService from '@/services/UserService'
import BonoService from '@/services/BonoService'
import dayjs from 'dayjs'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
var locale = require('dayjs/locale/es')
dayjs.locale('es')

Vue.use(Vuex)


const store = new Vuex.Store({
  state: {
    count: 0,
    authUser: null,
    user: null,
    profiles: null,
    modal: false,
    modal2: false,
    modalR: false,
    modalContent: 'login',
    modalContent2: 'uploadimage',
    navbar: false,
    navbarProfile: false,
    navbarAdminEstudiante: false,
    navbarAdminCurso: false,
    navbarAdminLocation: false,
    currentProfileId: null,
    currentLocation: null,
    currentCourseId: null,
    allProfiles: null,
    allCourses: null,
    skilllevels: null,
    locations: null,
    discountVols: null,
    typeofstudents: null,
    picture: null,
    picture_public_id: null,
    step: 1,
    bonoStep: 1,
    reservationState: {},
    bonoState: {},
    reservationSuccessfull: null,
    bonoSuccessfull: null,
    courseClasses: null,
    profileClasses: null,
    mentors: null,
    allClasses: null,
    selectedClass: null,
    mybonos: null,
    cameFromCalendar: false,
  },
  mutations: {
    ChangecameFromCalendar (state, fromCalendar) {
      state.cameFromCalendar = fromCalendar
    },
    addPicture (state, picture) {
      state.picture = picture;
    },
    addPicturePublicId (state, picture_public_id) {
      state.picture_public_id = picture_public_id;
    },
    incrementBookingStep (state) {
      if (state.step < 6) state.step++
    },
    decrementBookingStep (state) {
      if (state.step > 1) state.step--;
    },
    incrementBonoStep (state) {
      if (state.bonoStep < 4) state.bonoStep++
    },
    decrementBonoStep (state) {
      if (state.bonoStep > 1) state.bonoStep--;
    },
    reservationSuccessfull (state) {
      state.reservationSuccessfull = true;
    },
    updateBookingStep (state, newValue) {
      state.step = newValue;
    },
    updateBonoStep (state, newValue) {
      state.bonoStep = newValue;
    }, 
    updateAuthUser (state, authUser) {
      state.authUser = authUser
    },
    updateUser (state, user) {
      state.user = user
    },
    async updateUserDB (state) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      UserService.SigninFirebase(idToken)
        .then(response => {
          if (response.status === 200) {
            state.user = response.data
          }
        })
        .catch(()=>{})
    },
    updateProfiles (state, profiles) {
      state.profiles = profiles
    },
    updateAllProfiles (state, profiles) {
      state.allProfiles = profiles
    },

    async updateAllProfilesDB (state) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      ProfileService.getAllProfiles(idToken)
        .then(response => {
          if (response.status === 200) {
            state.allProfiles = response.data
          }
        })
        .catch(()=>{})
    },
    updateAllCourses (state, courses) {
      state.allCourses = courses
    },
    updateAllClasses (state, classes) {
      state.allClasses = classes
    },
    openModal (state, modalContent) {
      state.modalContent = modalContent
      state.modal = true
      const body = document.body
      body.classList.toggle('noScroll')
    },
    openModal2 (state, modalContent) {
      state.modalContent2 = modalContent
      state.modal2 = true
      const body = document.body
      body.classList.toggle('noScroll')
    },
    closeModal (state) {
      state.modal = false
      state.modalContent = 'login'
      const body = document.body
      body.classList.remove('noScroll')
      state.picture = null,
      state.picture_public_id = null
    },
    closeModal2 (state) {
      state.modal2 = false
      state.modalContent2 = 'login'
      const body = document.body
      body.classList.remove('noScroll')
    },
    openModalR (state, modalContent) {
      state.modalContent = modalContent
      state.modalR = true
      const body = document.body
      body.classList.toggle('noScroll')
    },
    closeModalR (state) {
      state.modalR = false
      state.modalContent = 'login'
      const body = document.body
      body.classList.remove('noScroll')
    },

    navbarHandler (state, show) {
      if (show) {
        state.navbar = true
      } else {
        state.navbar = false
      }
    },
    navbarProfileHandler (state, show) {
      if (show) {
        state.navbarProfile = true
      } else {
        state.navbarProfile = false
      }
    },
    navbarAdminEstudianteHandler (state, show) {
      if (show) {
        state.navbarAdminEstudiante = true
      } else {
        state.navbarAdminEstudiante = false
      }
    },
    navbarAdminCursoHandler (state, show) {
      if (show) {
        state.navbarAdminCurso = true
      } else {
        state.navbarAdminCurso = false
      }
    },
    navbarAdminLocationHandler (state, show) {
      if (show) {
        state.navbarAdminLocation = true
      } else {
        state.navbarAdminLocation = false
      }
    },
    updateCurrentProfile (state, id) {
      state.currentProfileId = id
    },
    updateCurrentCourse (state, id) {
      state.currentCourseId = id
    },
    updateReservation(state, reservation) {
      state.reservationState = reservation
    },
    updateBonoState(state, newBono) {
      state.bonoState = newBono
    },
    updateSkills (state) {
      ConfigurationService.getSkillLevel()
        .then(response => {
          let skillLevelsArr = []
          for (let i = 0; i < response.data.length; i++) {
            skillLevelsArr.push(response.data[i].name)
          }
          state.skilllevels = skillLevelsArr
        })
        .catch(()=>{})
    },
    updateLocations (state) {
      LocationService.GetLocations()
      .then(response => {
        if (response.status === 200) {
          state.locations = response.data
        }
      })
      .catch(()=>{})
    },
    updateTypeOfStudents (state) {
      ConfigurationService.getTypesOfStudents()
      .then(response => {
        if (response.status === 200) {
          state.typeofstudents = response.data
        }
      }).catch(()=>{})
    },
    updateDiscounts (state) {
      ConfigurationService.getRecargas()
      .then(response => {
        if (response.status === 200) {
          state.discountVols = response.data
        }
      }).catch(()=>{})
    },

    updateMentors (state) {
      ProfileService.getAllMentors()
        .then(response => {
          if (response.status === 200) {
            state.mentors = response.data
          }
        })
        .catch(()=>{})
    },
    updateCourses (state) {
      CourseService.GetCourses()
      .then(response => {
        if (response.status === 200) {
          state.allCourses = response.data
        }
      })
      .catch(()=>{})
    },
    updateLocation (state, id_location) {
      LocationService.GetLocation(id_location)
        .then(response => {
          if (response.status === 200) {
            state.currentLocation = response.data[0]
          }
        })
        .catch(()=>{})
    },
    async updateBonos (state) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      BonoService.GetMyBonos(idToken)
        .then(response => {
          if (response.status === 200) {
            state.mybonos = response.data
          }
        })
        .catch(()=>{})
    },
    clearCurrentLocation (state) {
      state.currentLocation = null
    },
    updateCourseClasses (state, courseId) {
      if (courseId === undefined) {
        state.courseClasses = null
      } else {
        ClassService.GetCourseClasses(courseId)
          .then(response => {
            if (response.status === 200) {
              state.courseClasses = response.data
            }
          })
          .catch(()=>{})
      }
    },

    updateProfileClasses (state, profileId) {
      if (profileId === undefined) {
        state.ProfileClasses = null
      } else {
        ClassService.GetProfileClasses(profileId)
          .then(response => {
            if (response.status === 200) {
              state.profileClasses = response.data
            }
          })
          .catch(()=>{})
      }

    },
    clearBookingDetails (state) {
      state.reservationState = {}
      state.step = 1
    },
    clearBonoDetails (state) {
      state.bonoState = {}
      state.bonoStep = 1
    },
    selectedClass (state, id) {
      state.selectedClass = id
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    avatar: state => {
      if (state.profiles) {
        const myprofile = state.profiles.filter(profile => profile.accountAdmin)
        if (myprofile.length > 0) {
          return myprofile[0].avatar
        } else {
          return '/img/navBar/avatar-default.svg'
        }
      } else {
        return '/img/navBar/avatar-default.svg'
      }
    },
    getProfile: (state) => (id) => {
      if (state.allProfiles) return state.allProfiles.find(profile => profile._id == id)
    },
    currentProfile: state => {
      // const profile_id = window.location.pathname.split('/')[2]
      // console.log('profile_id', profile_id, typeof profile_id, state.profiles[0]._id, typeof state.profiles[0]._id)
      if (state.profiles) {
        let currentProfileArr = state.profiles.filter(profile => profile._id === state.currentProfileId)
        // console.log('currentProfileArr', currentProfileArr[0].dateofbirth, typeof currentProfileArr[0].dateofbirth)
        return currentProfileArr[0]
      } else {
        return null
      }
    },

    currentProfileAdmin: state => {
      // const profile_id = window.location.pathname.split('/')[2]
      // console.log('profile_id', profile_id, typeof profile_id, state.profiles[0]._id, typeof state.profiles[0]._id)
      if (state.allProfiles) {
        let currentProfileArr = state.allProfiles.filter(profile => profile._id === state.currentProfileId)
        // console.log('currentProfileArr', currentProfileArr[0].dateofbirth, typeof currentProfileArr[0].dateofbirth)
        return currentProfileArr[0]
      } else {
        return null
      }
    },

    currentCourse: state => {
      if (state.allCourses) {
        let currentCourseArr = state.allCourses.filter(course => course._id === state.currentCourseId)
        return currentCourseArr[0]
      } else {
        return null
      }
    },
    getLocation: (state) => (id) => {
      return state.locations.find(location => location._id === id)
    },
    getCourse: (state) => (id) => {
      //return state.allCourses.filter(course => course._id === id)[0]
      return state.allCourses.find(course => course._id === id)
    },

    getClass: (state) => (id) => {
      return state.allClasses.find(clase => clase._id === id)
    },

    currentClass: state => {

        let currentClassArr = state.courseClasses.filter(clase => clase._id === state.reservationState.class_id)
        return currentClassArr[0]

    },

    currentClassFormated: (state, getters) => {

      const clase = getters.currentClass
      const claseF = {
        _id: clase._id,
        course_id: clase.course_id,
        start: clase.startclass,
        end: clase.endclass,
        endclass: dayjs(clase.endclass).format('dddd LL'),
        endclassHour: dayjs(clase.endclass).format('h:mm A'),
        maxstudents: clase.maxstudents,
        startclass: dayjs(clase.startclass).format('dddd LL'),
        startclassHour: dayjs(clase.startclass).format('h:mm A'),
        students: clase.students,
        skilllevel: getters.currentCourse.skilllevel,
        location: clase.location.name,
        mentor: clase.mentor.firstname + ' ' + clase.mentor.lastname,
        bookings: clase.bookings.length
      }
      
      return claseF
    },

    // mentors: state => {
    //   if (state.profiles) {
    //     let currentProfileArr = state.allProfiles.filter(profile => profile._id === state.currentProfileId)
    //     return currentProfileArr[0]
    //   } else {
    //     return null
    //   }      
    // },

    coursesForLevel: (state) => (level) => {
      return state.allCourses.filter(course => course.skilllevel != null && course.skilllevel == level);
    },

    courseClassesFormated: (state, getters) => {
      if (state.courseClasses) {
        
        // const mentor = state.mentors.filter(profile => profile._id === getters.currentCourse.mentor._id)[0]
        // console.log(mentor)

        // const location = state.locations.filter(location => location._id === getters.currentCourse.location._id)[0]
        // console.log(location)

        let arr = []
        for (let i = 0; i < state.courseClasses.length; i++) {
          const clase = state.courseClasses[i]
          const claseF = {
            _id: clase._id,
            course_id: clase.course_id,
            start: clase.startclass,
            end: clase.endclass,
            endclass: dayjs(clase.endclass).format('dddd LL'),
            endclassHour: dayjs(clase.endclass).format('h:mm A'),
            maxstudents: clase.maxstudents,
            startclass: dayjs(clase.startclass).format('dddd LL'),
            startclassHour: dayjs(clase.startclass).format('h:mm A'),
            students: clase.students,
            skilllevel: getters.currentCourse.skilllevel,
            location: clase.location.name,
            mentor: clase.mentor.firstname + ' ' + clase.mentor.lastname,
            bookings: clase.bookings.length
          }
          arr.push(claseF)
        }

        return arr
      } else {
        return null
      }
    },

    courseClassesFormatedAvailable: (state, getters) => {
      if (state.courseClasses) {
        

        let arr = []
        for (let i = 0; i < state.courseClasses.length; i++) {
          const clase = state.courseClasses[i]
          const claseF = {
            _id: clase._id,
            course_id: clase.course_id,
            start: clase.startclass,
            end: clase.endclass,
            endclass: dayjs(clase.endclass).format('dddd LL'),
            endclassHour: dayjs(clase.endclass).format('h:mm A'),
            maxstudents: clase.maxstudents,
            startclass: dayjs(clase.startclass).format('dddd LL'),
            startclassHour: dayjs(clase.startclass).format('h:mm A'),
            students: clase.students,
            skilllevel: getters.currentCourse.skilllevel,
            location: clase.location.name,
            mentor: clase.mentor.firstname + ' ' + clase.mentor.lastname,
            bookings: clase.bookings.length
          }


          if (claseF.bookings < claseF.maxstudents) {
            arr.push(claseF)
          }    
        }
        return arr
      } else {
        return null
      }
    },


    profileClassesFormated: (state) => {
      if (state.profileClasses) {
        
        let arr = []
        for (let i = 0; i < state.profileClasses.length; i++) {
          const clase = state.profileClasses[i]

          const course = state.allCourses.filter(course => course._id === clase.course_id)[0]
          console.log(course, '--------------------------fff')

          const claseF = {
            _id: clase._id,
            course_id: clase.course_id,
            start: clase.startclass,
            end: clase.endclass,
            endclass: dayjs(clase.endclass).format('dddd LL'),
            endclassHour: dayjs(clase.endclass).format('h:mm A'),
            maxstudents: clase.maxstudents,
            startclass: dayjs(clase.startclass).format('dddd LL'),
            startclassHour: dayjs(clase.startclass).format('h:mm A'),
            skilllevel: course.skilllevel,
            mentor: clase.mentor.firstname + ' ' + clase.mentor.lastname,
            location: clase.location.name
          }
          arr.push(claseF)
        }

        return arr
      } else {
        return null
      }
    },

    allCoursesFormated: (state) => {
      if (state.allCourses) {
        
        let arr = []
        for (let i = 0; i < state.allCourses.length; i++) {
          const course = state.allCourses[i]

          const lunesBlocks = course.calendar['1']
          // console.log('lunes', lunesBlocks.length)

          const martesBlocks = course.calendar['2']
          // console.log('Martes', martesBlocks.length)
          
          const miercolesBlocks = course.calendar['3']
          // console.log('Miercoles', miercolesBlocks)

          const juevesBlocks = course.calendar['4']
          // console.log('Jueves', juevesBlocks)
          
          const viernesBlocks = course.calendar['5']
          // console.log('Viernes', viernesBlocks)
          
          const sabadoBlocks = course.calendar['6']
          // console.log('Sabado', sabadoBlocks)
          
          const domingoBlocks = course.calendar['7']
          // console.log('Domingo', domingoBlocks)
          
          
          let horarioArr = []

          if (lunesBlocks.length === 1) {
            const dia = {
              dia: 'Lunes',
              start: lunesBlocks[0].start,
              end: lunesBlocks[0].end
            }
            horarioArr.push(dia)
          } 
          if (martesBlocks.length === 1) {
            const dia = {
              dia: 'Martes',
              start: martesBlocks[0].start,
              end: martesBlocks[0].end
            }
            horarioArr.push(dia)
          }
          if (miercolesBlocks.length === 1) {
            const dia = {
              dia: 'Miércoles',
              start: miercolesBlocks[0].start,
              end: miercolesBlocks[0].end
            }
            horarioArr.push(dia)
          }
          if (juevesBlocks.length === 1) {
            const dia = {
              dia: 'Jueves',
              start: juevesBlocks[0].start,
              end: juevesBlocks[0].end
            }
            horarioArr.push(dia)
          }
          if (viernesBlocks.length === 1) {
            const dia = {
              dia: 'Viernes',
              start: viernesBlocks[0].start,
              end: viernesBlocks[0].end
            }
            horarioArr.push(dia)
          }
          if (sabadoBlocks.length === 1) {
            const dia = {
              dia: 'Sabado',
              start: sabadoBlocks[0].start,
              end: sabadoBlocks[0].end
            }
            horarioArr.push(dia)
          }
          if (domingoBlocks.length === 1) {
            const dia = {
              dia: 'Domingo',
              start: domingoBlocks[0].start,
              end: domingoBlocks[0].end
            }
            horarioArr.push(dia)
          }

          // console.log('horarioArr', horarioArr)

          const horario = []
          for (let i = 0; i < horarioArr.length; i++) {
            const dia = horarioArr[i];
            const string = dia.dia + ' de ' + dia.start + ' a ' + dia.end
            horario.push(string)
          }
          // console.log('horario', horario)


          const cursoF = {
            active: course.active,
            _id: course._id,
            start: course.startcourse,
            end: course.endcourse,
            endcourse: dayjs(course.endcourse).format('dddd LL'),
            endcourseHour: dayjs(course.endcourse).format('h:mm A'),
            startcourse: dayjs(course.startcourse).format('dddd LL'),
            startcourseHour: dayjs(course.startcourse).format('h:mm A'),
            skilllevel: course.skilllevel,
            mentor: course.mentor.firstname + ' ' + course.mentor.lastname,
            location: course.location,
            name: course.name,
            horario: horario,
            classprice: course.classprice,
            classprice3: course.classprice3,
            classprice5: course.classprice5,
            classprice10: course.classprice10,
            maxstudents: course.maxstudents,
            conditions: course.conditions
          }
          arr.push(cursoF)
        }

        return arr
      } else {
        return null
      }
    },



  }
})

export default store
