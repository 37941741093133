<template>
  <div id="courses-admin">
    <div class="container-header-courses">
      <div class="title-courses">
        <h2 class="montserrat-bold white">Cursos</h2>
        
        <!-- NEW COURSE -->
        <button
          @click="newCourse" class="button-primary-medium">
          + Nuevo curso</button>
      </div>
    </div>


    <!-- LIST COURSE -->
    <div class="container-courses">
      
      <!-- COURSE COURSE -->
      <div
        v-for="(course, index) in this.$store.getters.allCoursesFormated"
        :key="index"
        class="content-course"
      >
        <div class="info-course">

          <div class="top-course">
            <!-- NAME COURSE -->
            <p class="montserrat-bold black">
              {{course.name}}
            </p>
      
            <!-- STATUS COURSE -->
            <p class="status-user OpenSans-Bold orange">
              {{course.active ? 'ACTIVE' : 'INACTIVE'}}
            </p>
          </div>           
          
          <div class="info-course-complementary">
            <div class="info-one">

              <!-- LEVEL COURSE -->
              <p class="opensans gray-medium is-size-text-normal mt-1">
                Principiante
              </p>

              <!-- NUMBER STUDENTS COURSE -->
              <p class="OpenSans-SemiBold gray-medium number-student is-size-text-normal">
                {{course.maxstudents}} Max. estudiantes
              </p>
            </div>

            <div class="info-two">
              <span class="opensans gray-medium is-size-text-normal">
                Precio:
              </span>

              <!-- PRICING -->
              <span class="OpenSans-SemiBold gray-medium ml-1 is-size-text-normal">
                {{course.classprice}}Є
              </span>

              <br>

              <div class="date-course">
                <div class="title-date">
                  <span class="opensans gray-medium is-size-text-normal">
                    Inicio:
                  </span>
                  <!-- DATE START COURSE -->
                  <span class="OpenSans-SemiBold gray-medium is-size-text-normal"> {{course.startcourse}}</span>
                </div>
                
                <div class="end-date">
                  <span class="opensans gray-medium is-size-text-normal">
                    Fin:
                  </span>
                  <!-- DATE END COURSE -->
                  <span class="OpenSans-SemiBold gray-medium is-size-text-normal"> {{course.endcourse}} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- BUTTON COURSE -->
        <div class="buttons-courses">
          <button
            class="button-medium-stroke"
             @click.prevent="goToCurso(course._id)">Editar</button>
            <br>
          <button
            class="button-medium-stroke button-delete"
            @click.prevent="deleteCurso(course._id)">Borrar</button>
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
import CourseService from '@/services/CourseService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'AdminCursos',
  components: {
  },


  data () {
    return {
      // courses: this.$store.state.allCourses
    }
  },

  methods: {
    // Go to course selected
    goToCurso (id) {
      this.$router.push(`/admin/cursos/${id}`)
    },

    // Delete course selected
    async deleteCurso (id) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await CourseService.DeleteCourse(id, idToken)

      if (response.status == 200) {
        this.$store.commit('updateCourses')
        this.success(response.data.msg)
      } else if (response.status == 203) {
        this.danger(response.data.msg)
      }
    },

    // New Profile
    newCourse () {
      this.$store.commit('openModal', 'newcourse')
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
  }
}
</script>

<style scoped>

#courses-admin {
  background: #FAFAFA;
  height: 100vh;
}

.container-header-courses {
  height: 98px;
  margin-top: 64px;
  background-color: #393939;
}

.title-courses {
  width: 80%;
  max-width: 1240px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
}

.title-courses h2 {
  font-size: 25px;
}

.container-courses {
  width: 95%;
  max-width: 882px;
  background-color: #fff;
  border: 1px solid #EDEDED;
  margin: auto;
  padding-bottom: 30px;
  margin-top: 20px;
}

.status-user {
  font-size: 13px;
}

.content-course {
  display: flex;
  background-color: #F5F3F3;
  width: 90%;
  margin: auto;
  margin-top: 15px;
  justify-content: space-between;
  padding: 13px 27px 12px 39px;
}

.info-course-complementary {
  display: flex;
  margin-top: 15px;
}

.info-two  {
  margin-left: 13px;
}

.number-student {
  margin-top: 8px;
}

.date-course  {
  margin-top: 8px;
}

.buttons-courses {
  margin-top: 5px;
}

.button-delete {
  margin-top: 15px;
}

.date-course {
  display: flex;
}

.end-date {
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {

  .top-course {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
  }

  .info-course-complementary {
    margin-top: 28px;
  }

  .content-course {
    display: block;
  }

  .buttons-courses {
    display: flex;
    margin-top: 20px;
  }

  .button-delete {
    margin-top: 0px;
    margin-left: 9px;
  }

}

@media only screen and (max-width: 576px) {
  .info-course-complementary {
    display: block;
  }

  .info-one {
    display: flex;
  }

  .info-two {
    margin-left: 0px;
    margin-top: 9px;
  } 

  .number-student  {
    margin-top: 4px;
    margin-left: 25px;
  }

  .date-course  {
    margin-top: 10px;
    
  }
}

@media only screen and (max-width: 430px) {

  .title-courses h2 {
    font-size: 20px
  }

  .title-courses {
    width: 87%;
    padding-top: 40px;
  }

  .button-primary-medium {
    width: 132px !important;
  }


  .content-course {
    padding: 13px 20px 12px 20px;
  }

  .info-two {
    margin-top: 6px;
  } 

  .info-course-complementary {
    margin-top: 20px;
  }

  .number-student  {
    margin-top: 0px;
    margin-left: 15px;
  }

  .date-course {
    display: block;
    margin-top: 6px;
  }

  .end-date {
    margin-top: 6px;
    margin-left: 0px;
  }
}

</style>