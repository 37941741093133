<template>
  <!-- <div id="navbar"> -->
  <div class="container-nav-bar-admin"> 
    <b-navbar id="navbarEstudiante">
          <template #brand>
              <b-navbar-item
                >
                <router-link
                  v-if="!$store.state.cameFromCalendar"
                  class="white is-size-extra-text montserrat-medium"
                  :to="{ name: 'AdminEstudiantes'}">


                  <svg class="arrow-back" width="19" height="19" viewBox="0 0 22 22" fill="none">
                    <path d="M20 11H2" stroke="white" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 20L2 11L11 2" stroke="white" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </router-link>
                <router-link
                  v-if="$store.state.cameFromCalendar"
                  class="white is-size-extra-text montserrat-medium"
                  :to="{ name: 'AdminCalendario'}">


                  <svg class="arrow-back" width="19" height="19" viewBox="0 0 22 22" fill="none">
                    <path d="M20 11H2" stroke="white" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 20L2 11L11 2" stroke="white" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </router-link>
                  <div v-if="$store.getters.currentProfileAdmin" class="montserrat-bold white name-user ml-5"> {{ $store.getters.currentProfileAdmin.firstname + ' ' + $store.getters.currentProfileAdmin.lastname}}</div>
                
              </b-navbar-item>
              
          </template>

          <template #end>

          </template>

          <template #start>

          </template>

          
      </b-navbar>

      <div id="nav-estudiante">
        <router-link
          class="montserrat-medium black sub-menu" :to="{ name: 'AdminEstudiante'}">Perfil</router-link>
        <router-link
          class="montserrat-medium black sub-menu" :to="{ name: 'AdminEstudianteClases'}">Clases</router-link>
      </div>

   </div>


</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'NavBar',
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      // Logout
      async logout() {
        // console.log('oio')
        await firebase.auth().signOut()
        this.$router.push('/').catch(()=>{})
      },
      showMenu () {
        document.getElementById('menu_logout_mobile').classList.toggle('disp-none')
      },

    }


}
</script>

<style lang="scss">

.container-nav-bar-admin {
  background-color: #EFEFEF;
  height: 180px;
}

#navbarEstudiante {
  display: flex;
  padding: 0 25px;
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0, 0, 0) !important;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
}

#nav-estudiante {
  padding-top: 80px;
  width: 62%;
  margin: auto;

  a {
    font-weight: bold;
    color: #000;
    padding: 33px 48px 19px 40px;

    &.router-link-exact-active {
      background-color: #F29713;
      padding: 33px 48px 19px 40px;
      color: #000 !important;
    }
  }
}

#chance_password .label,
#chance_password input::placeholder {
  color: #555!important;
}

.sub-menu {
  font-size: 15px;
  letter-spacing: 0.5px;
}
.image-avatar-panel {
  width: 23px;
  height: 23px;
  object-fit: cover;
  border-radius: 30px;
}

.circle-available {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00D6CB;
  position: absolute;
  top: 5px;
  right: 27px;
  /* border: 2px solid; */
}
.circle-review {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff9100;
  position: absolute;
  top: 10px;
  right: 25px;
}
.circle-disable {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #FF1515;
  position: absolute;
  top: 4px;
  right: 27px;
  /* border: 2px solid; */
}

.line-gray {
  border-top: 1px solid #ECECEC;
  height: 3px;
  width: 80%;
}
.arrow-avatar-nav-bar {
  width: 8px;
  height: 7px;
}
.resetPasswordModal {
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
}

.menu-my-profile {
    visibility: hidden;
}

.button {
    background-color: #fff !important;
}

.item-menu img {
    width: 20px;
}

.logout {
  float: right;
  margin-right: -9px;
  margin-top: -4px;
  padding-left: 26px;
  width: 40px;
}
.disp-none {
  display: none!important;
}
.btn-logout {
  top: 57px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 54px;
  position: absolute;
  right: 15px;
  width: 257px;
  background-color: #fff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.btn-logout a {
  background-color: #fff;
  color: #0D1239;
  font-size: 13.5px;
}

.title-header-section-create-account {
  margin-top: 73px;
  margin-left: 5%;
}

.form-login {
  margin-top: 70px;
}

.container-content-create-account {
  width: 85%;
}
.position-fixed {
  position: fixed!important;
}
.position-abosolute {
  position: absolute!important;
}

.label:not(:last-child) {
  margin-bottom: 0.3em !important;
}


@media only screen and (max-width: 890px) {

  .navbar-menu {
    background-color: #000 !important;
  }

  .arrow-back {
    width: 15px;
  }
}

@media only screen and (max-width: 726px)  {

  
  .image-profile-user {
    display: none;
  }

  #navbarEstudiante {
    padding: 0 16px;
  }

  #nav-estudiante {
    width: 90%;
  }

  #nav-estudiante a {
    font-size: 13px;
    font-weight: medium;
    padding: 24px 30px 20px 30px;
  }

  #nav-estudiante a.router-link-exact-active {
    padding: 24px 30px 20px 30px;
  }
}

/*
@media (min-width: 1024px) {
  .btn-logout {
    top: 29px;
  }
}
@media (max-width: 1024px) {

  .visiting-profile {
    width: 100%!important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* position: absolute!important; 
  }
  .visiting-profile-w {
    width: 100%!important;
  }
}*/

</style>