<template>
  <!-- <div id="navbar"> -->
  <div class="container-nav-bar"> 
    <b-navbar id="navbarProfile">
          <template #brand>
              <b-navbar-item v-if="$store.getters.currentProfile" tag="router-link" :to="{ path: '/panel/mis-perfiles' }">
                  <svg width="16" height="16" viewBox="0 0 22 22" fill="none">
                    <path d="M20 11H2" stroke="white" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 20L2 11L11 2" stroke="white" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <span
                  class="white montserrat-bold name-user ml-4">
                  {{ $store.getters.currentProfile.firstname + ' ' + $store.getters.currentProfile.lastname }}</span>
                
              </b-navbar-item>

              
          </template>

          <template #end>
              <div class="is-flex is-align-items-center">
                
              </div>
          </template>

          <template #start>
            <!-- <div id="nav">
              <router-link :to="{ name: 'Perfil', params: { profile_id: $store.state.currentProfileId }}">Mi Perfil</router-link> |
              <router-link :to="{ name: 'Clases', params: { profile_id: $store.state.currentProfileId }}">Mis Clases</router-link>
            </div> -->
          </template>

      </b-navbar>

      <div id="nav-profile">
        <router-link :to="{ name: 'Perfil', params: { profile_id: $store.state.currentProfileId }}">Mi Perfil</router-link>
        <router-link :to="{ name: 'Clases', params: { profile_id: $store.state.currentProfileId }}">Mis Clases</router-link>
      </div>

   </div>


</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'NavBar',
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      // Logout
      async logout() {
        // console.log('oio')
        await firebase.auth().signOut()
        this.$router.push('/').catch(()=>{})
      },
      showMenu () {
        document.getElementById('menu_logout_mobile').classList.toggle('disp-none')
      },

    }


}
</script>

<style lang="scss" scoped>

.container-nav-bar {
  background-color: #393939;
  height: 180px;
}

#navbarProfile {
  display: flex;
  padding: 0 25px;
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0, 0, 0);
  justify-content: space-between;
  align-items: center;
  z-index: 4;
}

#nav-profile {
  padding-top: 80px;
  width: 62%;
  margin: auto;

  a {
    font-weight: bold;
    color: #fff;
    padding: 33px 48px 19px 40px;

    &.router-link-exact-active {
      background-color: #F29713;
      color: #000 !important;
      padding: 33px 48px 19px 40px;
    }
  }
}

#chance_password .label,
#chance_password input::placeholder {
  color: #555!important;
}

.image-avatar-panel {
  width: 23px;
  height: 23px;
  object-fit: cover;
  border-radius: 30px;
}

.circle-available {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00D6CB;
  position: absolute;
  top: 5px;
  right: 27px;
  /* border: 2px solid; */
}
.circle-review {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff9100;
  position: absolute;
  top: 10px;
  right: 25px;
}
.circle-disable {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #FF1515;
  position: absolute;
  top: 4px;
  right: 27px;
  /* border: 2px solid; */
}

.line-gray {
  border-top: 1px solid #ECECEC;
  height: 3px;
  width: 80%;
}
.arrow-avatar-nav-bar {
  width: 8px;
  height: 7px;
}
.resetPasswordModal {
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
}

.menu-my-profile {
    visibility: hidden;
}

.button {
    background-color: #fff !important;
}

.item-menu img {
    width: 20px;
}

.logout {
  float: right;
  margin-right: -9px;
  margin-top: -4px;
  padding-left: 26px;
  width: 40px;
}
.disp-none {
  display: none!important;
}
.btn-logout {
  top: 57px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 54px;
  position: absolute;
  right: 15px;
  width: 257px;
  background-color: #fff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.btn-logout a {
  background-color: #fff;
  color: #0D1239;
  font-size: 13.5px;
}

.title-header-section-create-account {
  margin-top: 73px;
  margin-left: 5%;
}

.form-login {
  margin-top: 70px;
}

.container-content-create-account {
  width: 85%;
}
.position-fixed {
  position: fixed!important;
}
.position-abosolute {
  position: absolute!important;
}

.label:not(:last-child) {
    margin-bottom: 0.3em !important;
}

@media only screen and (max-width: 890px) {

  .navbar-menu {
    background-color: #000 !important;
  }
  
}

@media only screen and (max-width: 570px)  {
  .image-profile-user {
    display: none;
  }

  #navbarProfile {
    padding: 0 16px;
  }

  #nav-profile {
    width: 90%;
  }

  #nav-profile a {
    font-size: 13px;
    font-weight: medium;
    padding: 24px 19px 19px 19px;
  }

  #nav-profile a.router-link-exact-active {
    padding: 24px 19px 19px 19px;
  }
}



</style>