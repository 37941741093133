<template>
  <div id="cancel-class">
    <div class="header-modal">

      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold black">Cancelar Clase</h3>
        
        <!-- BUTTON CLOSE -->
        <a @click="closeModal">
          <div class="">
            <svg class="button-close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 2L2 18" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 2L18 18" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
    </div>
    
    <!-- INFO CANCEL CLASS -->
    <div class="info-cancel-class">
      <div class="container-cancel-class">
        <h3 class="montserrat-bold black">
          {{ this.classCourse.name }}</h3>

        <div class="schedule-class mt-6">
          <!-- DATE CLASS -->
          <span
            class="montserrat-semibold black">
            {{ this.selectedClass.startclass | toDay }}
          </span>
                
          <!-- HOUR CLASS -->
          <span
            class="montserrat black text-tranformation-uppercase">
             {{ this.selectedClass.startclass | toHours }} - {{ this.selectedClass.endclass | toHours }}
          </span>
        </div>

        <!-- NUMBER STUDENTS COURSE -->
        <p class="OpenSans-SemiBold gray-medium number-student mt-2">
          {{this.selectedClass.bookings.length}}/{{this.selectedClass.maxstudents}} Estudiantes
        </p>

        <!-- LEVEL COURSE -->
        <p class="opensans gray-medium mt-2">
          {{this.classCourse.skilllevel}}
        </p>

        <!-- PLACE COURSE -->
        <p class="OpenSans-medium gray-medium number-student mt-2">
          {{this.selectedClass.location.name}}
        </p>

        <div class="is-flex mt-2">

          <!-- MENTOR CLASS -->
          <span
            class="OpenSans-SemiBold black">
              Mentor:</span>
          <span
            class="opensans ml-2 black">
            {{this.selectedClass.mentor.firstname + " " + this.selectedClass.mentor.lastname}}</span>
        </div>
      
      </div>

      <div class="accionables">
        <p
          class="montserrat-semibold black mt-6">
          Esta seguro de cancelar esta clase ?</p>
        <div class="container-buttons mt-4">
          <button
            class="button-medium-stroke-black"
            @click.prevent="closeModal">Conservar clase</button>
          <button
            class="button-primary-extra-medium mleft-button"
            @click.prevent="cancelClass">Cancelar clase</button>
        </div>
      </div>
    </div>


  </div>


</template>

<script>

import ProfileService from '@/services/ProfileService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import ClassService from '@/services/ClassService'
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'CancelClass',
  components: {
  },
  data () {
    return {
      authUser: this.$store.state.authUser,
      selectedClass: this.$store.state.selectedClass,
      classCourse: this.$store.getters.getCourse(this.$store.state.selectedClass.course_id)
    }
  },
  filters: {
    toHours: (date) => {
      return dayjs(date).format('hh:mm A')
    },
    toDay: (date) => {
      let weekDayString = dayjs(date).format('dddd, ')
      let dayString = dayjs(date).format('D.')
      let monthString = dayjs(date).format('MMMM')
      return weekDayString.charAt(0).toUpperCase() + weekDayString.slice(1) + dayString + " " + monthString.charAt(0).toUpperCase() + monthString.slice(1)
    }
  },
  // METHODS
  methods: {
    // Close modal
    closeModal () {
      this.$store.commit('closeModal')
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },
    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
    async cancelClass() {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const deleteClass = await ClassService.DeleteClass(idToken, this.selectedClass._id)
      if (deleteClass.status === 200) {
        this.success("Guardado")
        this.$store.commit('updateBonos')
        this.closeModal()
        ClassService.GetClass()
          .then(response => {
            if (response.status === 200) {
              this.$store.commit('updateAllClasses', response.data) 
              this.$store.commit("updateCourseClasses", window.location.pathname.split("/")[3]);
            }
          }).catch(()=>{})
      } else {
        this.danger("No puedes borrar clases ya reservados de estudiantes")
      }
    }
  }
}
</script>


<style scoped>

.header-modal {
  width: 100%;
  background-color: #EFEFEF;
  height: 72px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.info-cancel-class {
  background-color: #fff;
  /*border: 1px solid #EDEDED;*/
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 50px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.container-cancel-class {
  width: 60%;
  margin: auto;
  padding-top: 50px;
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 35px;
}

.accionables {
  width: 60%;
  margin: auto;
}

.accionables p {
  font-size: 14px;
}

.mleft-button {
  margin-left: 10px;
}

@media only screen and (max-width: 732px) {
  .container-cancel-class {
    width: 85%;
  }

  .accionables {
    width: 85%;
  }
}

@media only screen and (max-width: 502px) {
  .container-header-modal {
    width: 80%;
  }

  .container-header-modal h3 {
    font-size: 16px;
  }

  .button-close {
    width: 18px;
    height: 18px;
  }

  .header-modal {
    height: 60px;
  }

  .container-cancel-class {
    width: 80%;
    padding-top: 30px;
  }


  .info-cancel-class {
    width: 95%;
  }

  .container-buttons {
    display: flex;
    flex-direction: column;
  }


  .mleft-button {
    margin-left: 0px;
    margin-top: 10px;
  }

  

}
</style>
