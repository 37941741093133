<template>
  <div id="panel-estudiantes-admin">
    
    <div id="panel-estudiantes-container">
      <!-- FILTERS -->
      <div class="filter">
        <div class="container-filter">
          <h2 class="montserrat-bold title-section-admin white">
            Perfiles</h2>
          
          <div class="mt-3 filter-group">

            <!-- SEARCH -->
            
            <b-field
              class="">
              <b-input
                  v-model="searchBox"
                  placeholder="Buscar estudiante "
                  type="search"
                  icon="magnify">
              </b-input>
              <p class="control">
                  <b-button
                    type="is-primary"
                    label="Search" />
              </p>
            </b-field>
           
            <!-- TYPE OF USER FILTER -->

            <div class="container-filter-select">
              <b-field class="type-user">
                <b-select
                  v-model="filterTypeUser"
                  placeholder="Tipo de usuario">
                    <option value="estudiante">Estudiante</option>
                    <!-- <option value="tutor">Tutor</option> -->
                    <option value="profesor">Profesor</option>
                </b-select>
              </b-field>

              <!-- STATUS USER FILTER -->
              <b-field class="status-user-filter">
                <b-select
                  v-model="filterStatus" 
                  placeholder="Status">
                    <option value="all">Todos</option>
                    <option value="active">Activo</option>
                    <option value="revision">Revisión</option>
                    <option value="inactive">Inactivo</option>
                </b-select>
              </b-field>
            </div>

          </div>
        </div>
      </div>
      
      <!-- LIST STUDENT-->
      <div id="profiles_container-admin">
        <div
          v-for="(profile, idx) in selectedProfilesSearch"
          :key="idx"
          class="profile_card_container-admin"
        >
          <!-- CARD STUDENT-->
          <div
            class="profile_card-admin"
            @click.prevent="goToProfile(profile._id)"
          >
            <div class="container-profile-card is-flex is-justify-content-space-between mt-1">

              <div class="is-flex is-align-items-center user-student">
                <!-- PICTURE USER-->
                <img
                  class="is-rounded image-avatar-panel"
                  :src="profile.avatar"
                >
              
                <div class="ml-4 info-user-student">
                  <!-- NAME USER STUDENT-->
                  <p 
                    class="montserrat-bold black name-user">
                    {{profile.firstname}} {{profile.lastname}}
                  </p>

                  <!-- STATUS USER STUDENT-->
                  <p 
                    class="status-student OpenSans-Bold orange">
                    {{profile.status === 'active' ? 'ACTIVO' : profile.status === 'inactive' ? 'INACTIVO' : 'REVISIÓN' }}</p>
                </div>
              </div>

              <!-- BUTTON GO TO DETAIL PROFILE STUDENT-->
              <div class="go-profile">
                <div class="button button-go-to-profile">
                  <svg width="11" height="20" viewBox="0 0 11 20" fill="none">
                    <path d="M1 19L10 10L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>

            </div>
          </div>

        </div>

        <!-- <div class="new_profile_card_container">
          <div
            class="new_profile_card  black"
            @click.prevent="newprofile"
          >
            <p class="montserrat-semibold">+ Agregar nuevo estudiante</p>
          </div>
        </div> -->

      </div>

    </div>



  </div>
</template>

<script>


export default {
  name: 'AdminEstudiantes',
  components: {
  },
  data () {
    return {
      // allProfiles: this.$store.state.allProfiles,
      selectedProfilesType: null,
      selectedProfilesStatus: null,
      selectedProfilesSearch: null,
      filterTypeUser: 'estudiante',
      filterStatus: 'active',
      searchBox: null
    }
  },

  mounted () {

      this.$store.commit('updateAllProfilesDB')
      if (this.allProfiles){
        this.filterProfiles(this.filterTypeUser, this.filterStatus)
        this.searchProfiles ()
      }
      window.scrollTo(0, 0)
      this.$store.commit('ChangecameFromCalendar', false)

  },

  computed: {
    allProfiles() {
      return this.$store.state.allProfiles
    } 
  },

  watch: {

    allProfiles: function () {
      this.filterProfiles(this.filterTypeUser, this.filterStatus)
      this.searchProfiles ()
    },


    filterTypeUser: function () {
      this.filterProfiles(this.filterTypeUser, this.filterStatus)
      this.searchProfiles ()
    },

    filterStatus: function () {
      this.filterProfiles(this.filterTypeUser, this.filterStatus)
      this.searchProfiles ()
    },

    searchBox: function () {
      this.searchProfiles ()
    }
  },

  methods: {
    // Go to profile selected
    goToProfile (id) {
      this.$router.push(`/admin/estudiantes/${id}`)
    },

    // New Profile
    newprofile () {
      this.$store.commit('openModal', 'newprofile')
    },

    // Filter
    filterProfiles (filterTypeUser, filterStatus) {

      if (filterTypeUser === 'estudiante') {
        let estudianteProfiles = this.allProfiles.filter( profile => !profile.accountAdmin || !profile.isMentor)
        this.selectedProfilesType = estudianteProfiles
      } else if (filterTypeUser === 'profesor') {
        let profesorProfiles =  this.allProfiles.filter( profile => profile.isMentor)
        this.selectedProfilesType = profesorProfiles
      } else if (filterTypeUser === 'tutor') {
        let tutorProfiles = this.allProfiles.filter( profile => profile.accountAdmin && !profile.isMentor)
        this.selectedProfilesType = tutorProfiles
      }

      if (filterStatus === 'active') {
        let estudianteProfiles = this.selectedProfilesType.filter( profile => profile.status === 'active')
        this.selectedProfilesStatus = estudianteProfiles
      } else if (filterStatus === 'inactive') {
        let profesorProfiles =  this.selectedProfilesType.filter( profile => profile.status === 'inactive')
        this.selectedProfilesStatus = profesorProfiles
      } else if (filterStatus === 'revision') {
        let tutorProfiles = this.selectedProfilesType.filter( profile => profile.status === 'revision')
        this.selectedProfilesStatus = tutorProfiles
      } else if (filterStatus === null || filterStatus === 'all') {
        this.selectedProfilesStatus = this.selectedProfilesType
      }
    },

    // Search
    searchProfiles () {
      if (this.searchBox === '' || this.searchBox === null) {
        this.selectedProfilesSearch = this.selectedProfilesStatus
      } else {
        let searchString = this.searchBox.toLowerCase()
        const searchedProfiles = this.selectedProfilesStatus.filter(profile => {
          if (
            profile.firstname === undefined ||
            profile.firstname === undefined
            ) {
            return false
          } else {
            return (
              profile.firstname.toLowerCase().includes(searchString) ||
              profile.lastname.toLowerCase().includes(searchString)
            )
          }
        })

        this.selectedProfilesSearch = searchedProfiles
      }
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  }
}
</script>

<style>

#panel-estudiantes-admin {
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #FAFAFA;
  height: 100vh;
  padding-bottom: 30px;
}

#panel-estudiantes-container {
  width: 100%;
}

.filter {
  background-color: #393939;
  padding-top: 33px !important;
  padding-bottom: 20px;
  display: flex;
}


.container-filter {
  width: 69%;
  max-width: 848px;
  margin: auto;
}

.container-filter h2 {
  font-size: 27px;
}

.container-filter-select {
  display: flex;
}

.type-user {
  margin-left: 10px;
}

.status-user-filter {
  margin-left: 10px;
}

#profiles_container-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  max-width: 800px;
  width: 70%;
  margin: auto;
}


.profile_card_container-admin {
  width: 90%;
}

.profile_card-admin {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 95%;
  margin: 0px 20px 10px 0px !important;
  padding: 5px 7px 5px 26px;
  min-height: 40px;
  border-radius: 2px;
}

.container-profile-card {
  min-width: 90%;
}

.image-avatar-panel {
  width: 30px;
  height: 30px;
}

.user-student {
  width: 90%;
}

.info-user-student {
  width: 80%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.name-user {
  font-size: 15px;
}

.status-student {
  font-size: 13px;
}

.go-profile {
  border-left: 1px solid #ECECEC;
  width: 10%;
}

.button {
  border: none !important;
  margin-left: 0px;
}

.new_profile_card_container {
  width: 90%;
}

.new_profile_card {
  width: 90%;
  background-color: #fff;
  margin: 0px 20px 15px 20px;
  min-height: 50px;
  border: 1.9px dashed #000;
  border-radius: 2px;
}

.new_profile_card p {
  font-size: 14px;
  margin-top: 12px;
  margin-left: 25px;
}

.button-go-to-profile {
  margin-left: 15px;
}

.filter-group {
  display: flex;
}


@media only screen and (max-width: 865px) {
  #profiles_container-admin {
    width: 100%;
  }
  
  .container-filter {
    width: 90%;
  }

  .filter-group {
    display: flex;
    flex-direction: column;
  }

  

  .type-user {
    margin-left: 0px;
  }


  .button {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

@media only screen and (max-width: 641px) {
  
  
  .profile_card-admin {
    width: 100%;
    padding-right: 20px;
  }

  .info-user-student p {
    font-size: 13px;
  }

  .go-profile {
    width: 7%;
    margin-left: 12px;
    padding-left: 0px;
  }

  .button {
    margin-left: 0px;
  }

}


</style>