<template>
    <div id="pay">
        <div class="header">
          <div class="container-header">
            <p class="montserrat-semibold white">Recargar cuenta</p>
            
            <!-- CLOSE MODAL -->
            <div
                @click="$store.commit('closeModal')" 
                class="close">
                <svg class="button-close" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 2L2 17" stroke="white" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 2L17 17" stroke="white" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
          </div>
        </div>

        <div class="content-pay">
            <h3 class="montserrat-bold gray-meidum black is-size-medium-big-text">
                Recarga saldo en tu cuenta para reservar clases</h3>
            <p class="opensans gray-medium">Aprovecha las bonificaciones. Cuanto más recargas, más saldo te regalamos:</p>

            <Refill />
        </div>
    </div>
</template>

<script>
import Refill from '@/components/Pays/refills-pay.vue'

export default {
    components: {
        Refill,
    }
}
</script>

<style scoped>

.header {
    background-color: #484848;
    height: 85px;
    padding-top: 42px;
}

.container-header {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.content-pay {
    width: 60%;
    margin: auto;
    margin-top: 62px;
}

.content-pay h3 {
    margin-left: 27px;
}

.content-pay p {
    margin-left: 27px;
    margin-top: 15px;
    margin-bottom: 45px;
}

@media only screen and (max-width: 948px) {
    .content-pay {
        width: 80%;
    }
}

@media only screen and (max-width: 778px) {
    .content-pay {
        width: 90%;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 562px) {

    .header {
        height: 59px;
        padding-top: 19px;
    }

    .container-header p {
        font-size: 14px;
    }
    
    .content-pay {
        margin-top: 42px;
    }
    .content-pay h3 {
        margin-left: 10px;
    }

    .content-pay p {
        margin-left: 10px;
        margin-bottom: 20px;
        width: 90%;
    }

    .button-close {
        width: 15px;
        height: 15px;
    }

}

</style>