<template>

  <div id="content_onlineSession calendar" class="container-money mt-6 desactive_">

    <!-- Title 
    <div class="mb-6">
      <h2 class="title-regular white">Weekly Schedule</h2>
      <p class="paragraph-content gray-ultra-light">Choose your weekly schedule. Remember that anyone can book a session within these hours.</p>
    </div> -->

    <!-- Monday -->
    <div id="Monday" class="day is-flex is-flex-direction-column">
      <div class="weekly-schedule mt-2">
        <b-field class="is-flex check-online-sessions">
            <b-checkbox
              class="black montserrat-medium"
              v-model="checkboxMonday"
              :disabled="$store.getters.currentCourse.active ? true : false">
                Lunes
            </b-checkbox>
        </b-field>
        <div class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in mondayBlocks"
            :key="`block-${index}`"
          >
            <b-field
              class="is-flex">
              <b-select
                v-model="mondayBlocks[index].start"
                
                placeholder="Start"
                :disabled="$store.getters.currentCourse.active ? true : false">
                  <option
                    v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}
                  </option>
              </b-select>
              <span
                class="black ml-4 mr-4 mt-3">
                a
              </span>
              <b-select
                v-model="mondayBlocks[index].end" placeholder="End" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option
                    v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}
                  </option>
              </b-select>
              <a
                style="width: 25px;" @click="deleteBlockMonday(index)">
                <svg class="ml-3 mt-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>

        <!-- <div class="is-flex weekl-schedule-content-2">
          <a @click="createBlockMonday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

    <!-- Tuesday -->
    <div id="Tuesday" class="day is-flex is-flex-direction-column">
      <div class="weekly-schedule mt-2">
        <b-field class="is-flex check-online-sessions">
            <b-checkbox
              class="black montserrat-medium"
              v-model="checkboxTuesday"
              :disabled="$store.getters.currentCourse.active ? true : false">
               Martes
            </b-checkbox>
        </b-field>
        <div class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in tuesdayBlocks"
            :key="`block-${index}`"
          >
            <b-field class="is-flex">
              <b-select v-model="tuesdayBlocks[index].start" placeholder="Start" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <span
                class="black ml-4 mr-4 mt-3 montserrat-medium">
                a
              </span>
              <b-select v-model="tuesdayBlocks[index].end" placeholder="End" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <a
                style="width: 25px;"
                class="ml-3 mt-2"
                @click="deleteBlockTuesday(index)">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>
        <!-- <div class="is-flex weekl-schedule-content-2">
          <a
            @click="createBlockTuesday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

    <!-- Wednesday -->
    <div id="Wednesday" class="day is-flex is-flex-direction-column">
      <div class="weekly-schedule mt-2">
        <b-field class="is-flex check-online-sessions">
            <b-checkbox
              class="black montserrat-medium"
              v-model="checkboxWednesday"
              :disabled="$store.getters.currentCourse.active ? true : false">
                Miércoles
            </b-checkbox>
        </b-field>
        <div class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in wednesdayBlocks"
            :key="`block-${index}`"
          >
            <b-field class="is-flex">
              <b-select v-model="wednesdayBlocks[index].start" placeholder="Start" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <span
                class="black ml-4 mr-4 mt-3 montserrat-medium">
                a
              </span>
              <b-select v-model="wednesdayBlocks[index].end" placeholder="End" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <a style="width: 25px;" @click="deleteBlockWednesday(index)">
                <svg class="ml-3 mt-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>
        <!-- <div class="is-flex weekl-schedule-content-2">
          <a @click="createBlockWednesday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

    <!-- Thursday -->
    <div id="Thursday" class="day is-flex is-flex-direction-column">
      <div class="weekly-schedule mt-2">
        <b-field class="is-flex check-online-sessions">
            <b-checkbox
              class="black montserrat-medium"
              v-model="checkboxThursday"
              :disabled="$store.getters.currentCourse.active ? true : false">
                Jueves
            </b-checkbox>
        </b-field>
        <div class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in thursdayBlocks"
            :key="`block-${index}`"
          >
            <b-field class="is-flex" >
              <b-select v-model="thursdayBlocks[index].start" placeholder="Start" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <span
                class="black ml-4 mr-4 mt-3 montserrat-medium">
                a
              </span>
              <b-select v-model="thursdayBlocks[index].end" placeholder="End" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <a
                style="width: 25px;"
                @click="deleteBlockThursday(index)">
                <svg class="ml-3 mt-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>
        <!-- <div class="is-flex weekl-schedule-content-2">
          <a @click="createBlockThursday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

    <!-- Friday -->
    <div id="Friday" class="day is-flex is-flex-direction-column">
      <div class="weekly-schedule mt-2">
        <b-field class="is-flex check-online-sessions">
            <b-checkbox
              class="black montserrat-medium"
              v-model="checkboxFriday"
              :disabled="$store.getters.currentCourse.active ? true : false">
                Viernes
            </b-checkbox>
        </b-field>
        <div class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in fridayBlocks"
            :key="`block-${index}`"
          >
            <b-field class="is-flex">
              <b-select v-model="fridayBlocks[index].start" placeholder="Start" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <span
                class="black montserrat-medium ml-4 mr-4 mt-3">
                a
              </span>
              <b-select v-model="fridayBlocks[index].end" placeholder="End" :disabled="$store.getters.currentCourse.active ? true : false">
                  <option v-for="(hour, index) in hoursOfDay" :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <a style="width: 25px;" @click="deleteBlockFriday(index)">
                <svg class="ml-3 mt-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>
        <!-- <div class="is-flex weekl-schedule-content-2">
          <a @click="createBlockFriday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

    <!-- Saturday -->
    <div
        id="Saturday"
        class="day is-flex is-flex-direction-column">
      <div
        class="weekly-schedule mt-2">
        <b-field
            class="is-flex check-online-sessions">
            <b-checkbox
              class="wblack montserrat-medium"
              v-model="checkboxSaturday"
              :disabled="$store.getters.currentCourse.active ? true : false">
                Sábado
            </b-checkbox>
        </b-field>
        <div
            class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in saturdayBlocks"
            :key="`block-${index}`"
          >
            <b-field class="is-flex">
              <b-select
                v-model="saturdayBlocks[index].start"
                placeholder="Start"
                :disabled="$store.getters.currentCourse.active ? true : false">
                  <option
                    v-for="(hour, index) in hoursOfDay"
                    :key="index" :value="hour">{{hour}}
                  </option>
              </b-select>
              <span
                class="black montserrat-medium ml-4 mr-4 mt-3">
                a
             </span>
              <b-select
                v-model="saturdayBlocks[index].end"
                placeholder="End"
                :disabled="$store.getters.currentCourse.active ? true : false">
                  <option
                    v-for="(hour, index) in hoursOfDay"
                    :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <a style="width: 25px;" @click="deleteBlockSaturday(index)">
                <svg class="ml-3 mt-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>
        <!-- <div class="is-flex weekl-schedule-content-2">
          <a @click="createBlockSaturday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

    <!-- Sunday -->
    <div
        id="Sunday"
        class="day is-flex is-flex-direction-column">
      <div
        class="weekly-schedule mt-2">
        <b-field
            class="is-flex check-online-sessions">
            <b-checkbox
              class="wblack montserrat-medium"
              v-model="checkboxSunday"
              :disabled="$store.getters.currentCourse.active ? true : false">
                Domingo
            </b-checkbox>
        </b-field>
        <div
            class="weekl-schedule-content-1">
          <div
            v-for="(block, index) in sundayBlocks"
            :key="`block-${index}`"
          >
            <b-field class="is-flex">
              <b-select
                v-model="sundayBlocks[index].start"
                placeholder="Start"
                :disabled="$store.getters.currentCourse.active ? true : false">
                  <option
                    v-for="(hour, index) in hoursOfDay"
                    :key="index" :value="hour">{{hour}}
                  </option>
              </b-select>
              <span
                class="black montserrat-medium ml-4 mr-4 mt-3">
                a
             </span>
              <b-select
                v-model="sundayBlocks[index].end"
                placeholder="End"
                :disabled="$store.getters.currentCourse.active ? true : false">
                  <option
                    v-for="(hour, index) in hoursOfDay"
                    :key="index" :value="hour">{{hour}}</option>
              </b-select>
              <a style="width: 25px;" @click="deleteBlockSunday(index)">
                <svg class="ml-3 mt-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.17383H2.55556H15" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.889 4.17333V2.58667C4.889 2.16586 5.05288 1.76228 5.34461 1.46472C5.63633 1.16717 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.16717 10.6556 1.46472C10.9473 1.76228 11.1112 2.16586 11.1112 2.58667V4.17333M13.4445 4.17333V15.28C13.4445 15.7008 13.2807 16.1044 12.9889 16.4019C12.6972 16.6995 12.3015 16.8667 11.889 16.8667H4.11122C3.69866 16.8667 3.303 16.6995 3.01128 16.4019C2.71955 16.1044 2.55566 15.7008 2.55566 15.28V4.17333H13.4445Z" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66675 7.11963L5.66675 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 7.11963L8 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3333 7.11963L10.3333 13.4663" stroke="black" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </b-field>

            <div class="spacer-30"></div>
          </div>
        </div>
        <!-- <div class="is-flex weekl-schedule-content-2">
          <a @click="createBlockSunday">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1V17" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
                <path d="M17 9L1 9" stroke="black" stroke-width="0.775862" stroke-linecap="round"/>
            </svg>
          </a>
        </div> -->
      </div>
    </div>

   


    <div class="spacer-50"></div>
    <button
        :class="$store.getters.currentCourse.active ? 'button-disabled' : 'button-primary-extra-medium'"
        @click="updateCalendar"
        :disabled="$store.getters.currentCourse.active ? true : false">
        Activar Curso</button>

    <!-- <button
        class="button-secondary-extra-medium"
        @click="activateCourse">
        Activate Course</button> -->

    <div v-if="loader">
      <Loading/>
    </div>

  </div>

</template>

<script>
import Loading from '@/components/modals/loader.vue'
import UserService from '@/services/UserService'
import CalendarService from '@/services/CalendarService'
import CourseService from '@/services/CourseService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'Calendar',
  components: {
    Loading
  },
  data () {
    return {
      loader: false,
      // calendarId: this.$store.getters.currentCourse.calendar,
      user: {},
      isSwitchedCustom: 'Desactive',
      checkboxGroup: [],

      authUser: null,
      hoursOfDay: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'],

      mondayBlocks: [],
      tuesdayBlocks: [],
      wednesdayBlocks: [],
      thursdayBlocks: [],
      fridayBlocks: [],
      saturdayBlocks: [],
      sundayBlocks: [],
      checkboxMonday: true,
      checkboxTuesday: true,
      checkboxWednesday: true,
      checkboxThursday: true,
      checkboxFriday: true,
      checkboxSaturday: true,
      checkboxSunday: true,

      myCalendar: {}
    }
  },
  metaInfo: {
    title: 'emitus | Monetize your talent'
  },
  created () {
    // Get my Calendar
    firebase.auth().currentUser.getIdToken(true)
      .then(async idToken => {
        const myCalendar = await CalendarService.getCalendar(idToken, this.$store.getters.currentCourse.calendar._id)
        // console.log('myCalendar ->>', myCalendar)
        if (myCalendar.data) {
          this.mondayBlocks = myCalendar.data['1']
          if (this.mondayBlocks.length === 0) {
            this.checkboxMonday = false
          } else {
            this.checkboxMonday = true
          }

          this.tuesdayBlocks = myCalendar.data['2']
          if (this.tuesdayBlocks.length === 0) {
            this.checkboxTuesday = false
          } else {
            this.checkboxTuesday = true
          }

          this.wednesdayBlocks = myCalendar.data['3']
          if (this.wednesdayBlocks.length === 0) {
            this.checkboxWednesday = false
          } else {
            this.checkboxWednesday = true
          }

          this.thursdayBlocks = myCalendar.data['4']
          if (this.thursdayBlocks.length === 0) {
            this.checkboxThursday = false
          } else {
            this.checkboxThursday = true
          }

          this.fridayBlocks = myCalendar.data['5']
          if (this.fridayBlocks.length === 0) {
            this.checkboxFriday = false
          } else {
            this.checkboxFriday = true
          }

          this.saturdayBlocks = myCalendar.data['6']
          if (this.saturdayBlocks.length === 0) {
            this.checkboxSaturday = false
          } else {
            this.checkboxSaturday = true
          }

          this.sundayBlocks = myCalendar.data['7']
          if (this.sundayBlocks.length === 0) {
            this.checkboxSunday = false
          } else {
            this.checkboxSunday = true
          }
        } else {
          this.checkboxMonday = false
          this.checkboxTuesday = false
          this.checkboxWednesday = false
          this.checkboxThursday = false
          this.checkboxFriday = false
          this.checkboxSaturday = false
          this.checkboxSunday = false
        }
      })
  },
  watch: {
    checkboxMonday: function () {
      if (!this.checkboxMonday) {
        this.mondayBlocks = []
      } else {
        this.mondayBlocks = [{ start: '', end: '' }]
      }
    },
    checkboxTuesday: function () {
      if (!this.checkboxTuesday) {
        this.tuesdayBlocks = []
      } else {
        this.tuesdayBlocks = [{ start: '', end: '' }]
      }
    },
    checkboxWednesday: function () {
      if (!this.checkboxWednesday) {
        this.wednesdayBlocks = []
      } else {
        this.wednesdayBlocks = [{ start: '', end: '' }]
      }
    },
    checkboxThursday: function () {
      if (!this.checkboxThursday) {
        this.thursdayBlocks = []
      } else {
        this.thursdayBlocks = [{ start: '', end: '' }]
      }
    },
    checkboxFriday: function () {
      if (!this.checkboxFriday) {
        this.fridayBlocks = []
      } else {
        this.fridayBlocks = [{ start: '', end: '' }]
      }
    },
    checkboxSaturday: function () {
      if (!this.checkboxSaturday) {
        this.saturdayBlocks = []
      } else {
        this.saturdayBlocks = [{ start: '', end: '' }]
      }
    },
    checkboxSunday: function () {
      if (!this.checkboxSunday) {
        this.sundayBlocks = []
      } else {
        this.sundayBlocks = [{ start: '', end: '' }]
      }
    },

    mondayBlocks: function () {
      if (this.mondayBlocks.length === 0) {
        this.checkboxMonday = false
      }
    },
    tuesdayBlocks: function () {
      if (this.tuesdayBlocks.length === 0) {
        this.checkboxTuesday = false
      }
    },
    wednesdayBlocks: function () {
      if (this.wednesdayBlocks.length === 0) {
        this.checkboxWednesday = false
      }
    },
    thursdayBlocks: function () {
      if (this.thursdayBlocks.length === 0) {
        this.checkboxThursday = false
      }
    },
    fridayBlocks: function () {
      if (this.fridayBlocks.length === 0) {
        this.checkboxFriday = false
      }
    },
    saturdayBlocks: function () {
      if (this.saturdayBlocks.length === 0) {
        this.checkboxSaturday = false
      }
    },
    sundayBlocks: function () {
      if (this.sundayBlocks.length === 0) {
        this.checkboxSunday = false
      }
    }
  },
  methods: {


    async activateCourse () {
      this.loader = true
      // window.alert('Activamos el curso')
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await CourseService.ActivateCourse(idToken, this.$store.getters.currentCourse._id)
      // console.log('6969696', response)

      if (response.status === 201) {
        this.$store.commit('updateCourseClasses', window.location.pathname.split('/')[3])
        await firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            await CourseService.GetCourses(idToken)
              .then(response => {
                if (response.status === 200) {
                  this.loader = false
                  this.$store.commit('updateAllCourses', response.data)
                }
              })
              .catch(()=>{
                this.loader = false
              })
          })
          .catch(()=>{
            this.loader = false
          })
      } else {
        this.loader = false
      }

    },

    notificationCalendarErr (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    notificationCalendar (msg, response) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Monday Blocks
    async createBlockMonday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.mondayBlocks.push({ start: '', end: '' })
        if (!this.checkboxMonday) {
          this.checkboxMonday = true
        }
      }
    },
    async deleteBlockMonday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.mondayBlocks.splice(index, 1)
      }
    },

    // Tuesday Blocks
    async createBlockTuesday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.tuesdayBlocks.push({ start: '', end: '' })
        if (!this.checkboxTuesday) {
          this.checkboxTuesday = true
        }
      }
    },
    async deleteBlockTuesday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.tuesdayBlocks.splice(index, 1)
      }
    },

    // Wednesday Blocks
    async createBlockWednesday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.wednesdayBlocks.push({ start: '', end: '' })
        if (!this.checkboxWednesday) {
          this.checkboxWednesday = true
        }
      }
    },
    async deleteBlockWednesday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.wednesdayBlocks.splice(index, 1)
      }
    },

    // Thursday Blocks
    async createBlockThursday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.thursdayBlocks.push({ start: '', end: '' })
        if (!this.checkboxThursday) {
          this.checkboxThursday = true
        }
      }
    },
    async deleteBlockThursday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.thursdayBlocks.splice(index, 1)
      }
    },

    // Friday Blocks
    async createBlockFriday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.fridayBlocks.push({ start: '', end: '' })
        if (!this.checkboxFriday) {
          this.checkboxFriday = true
        }
      }
    },
    async deleteBlockFriday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.fridayBlocks.splice(index, 1)
      }
    },

    // Saturday Blocks
    async createBlockSaturday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.saturdayBlocks.push({ start: '', end: '' })
        if (!this.checkboxSaturday) {
          this.checkboxSaturday = true
        }
      }
    },
    async deleteBlockSaturday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.saturdayBlocks.splice(index, 1)
      }
    },

    // Sunday Blocks
    async createBlockSunday () {
      if (this.$store.getters.currentCourse.active === false) {
        this.sundayBlocks.push({ start: '', end: '' })
        if (!this.checkboxSunday) {
          this.checkboxSunday = true
        }
      }
    },
    async deleteBlockSunday (index) {
      if (this.$store.getters.currentCourse.active === false) {
        this.sundayBlocks.splice(index, 1)
      }
    },

    // Save Calendar -------
    async updateCalendar () {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        for (let i = 0; i < this.mondayBlocks.length; i++) {
          if (this.mondayBlocks[i].start === '' || this.mondayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Lunes.')
            return
          }
        }
        for (let i = 0; i < this.tuesdayBlocks.length; i++) {
          if (this.tuesdayBlocks[i].start === '' || this.tuesdayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Martes.')
            return
          }
        }
        for (let i = 0; i < this.wednesdayBlocks.length; i++) {
          if (this.wednesdayBlocks[i].start === '' || this.wednesdayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Miércoles.')
            return
          }
        }
        for (let i = 0; i < this.thursdayBlocks.length; i++) {
          if (this.thursdayBlocks[i].start === '' || this.thursdayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Jueves.')
            return
          }
        }
        for (let i = 0; i < this.fridayBlocks.length; i++) {
          if (this.fridayBlocks[i].start === '' || this.fridayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Viernes.')
            return
          }
        }
        for (let i = 0; i < this.saturdayBlocks.length; i++) {
          if (this.saturdayBlocks[i].start === '' || this.saturdayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Sábado.')
            return
          }
        }
        for (let i = 0; i < this.sundayBlocks.length; i++) {
          if (this.sundayBlocks[i].start === '' || this.sundayBlocks[i].end === '') {
            this.notificationCalendarErr('Ey! hay un bloque vacío el Domingo.')
            return
          }
        }
        const response = await CalendarService.updateCalendar(idToken, this.mondayBlocks, this.tuesdayBlocks, this.wednesdayBlocks, this.thursdayBlocks, this.fridayBlocks, this.saturdayBlocks, this.sundayBlocks, this.$store.getters.currentCourse.calendar._id)
        this.notificationCalendar('Calendar updated successfully', response)
        await this.activateCourse()

      } catch (error) {
        this.notificationCalendarErr(error.response.data.msg)
      }
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollSideNav)
  }
}

</script>

<style>
.disabled-div {
  pointer-events: none;
  background-color: #f0f0f0;
}

#timezone select {
  appearance:none;
}

.select {
  min-width: 100px;
}

.hidde-modal-custom {
  display: none;
}
.custom-overwrites {
  margin-top: 112px;
}

.custom-overwrites-container a {
  font-weight: normal;
  font-size: 14px;
  text-decoration: underline!important;
}
.weekl-schedule-content-2 {
  justify-content: flex-end;
  gap: 44px;
  margin-bottom: 59px;
  grid-column: 3 / -2;
}
.weekl-schedule-content-1 {
  grid-row: 2 / 3;
  grid-column: 3 / 1;
  gap: 15px;
  align-items: center;
}
.check-online-sessions {
  align-self: start!important;
  grid-column: 1 / 2;
}
.weekly-schedule {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid rgba(238, 238, 238, 0.4);
  padding-bottom: 32px;
  margin-bottom: 42px;
}
.switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}
.container-money {
  max-width: 579px;
  margin-top: 72px;
}

#content_onlineSession .b-checkbox.checkbox input[type=checkbox] + .check {
  border: 1px solid rgba(238, 238, 238, 0.2) !important;
}
@media (max-width: 1024px) {
  .hidde-mobile-avatar {
    display: none;
  }
  #content_onlineSession .field-body > .field:not(.is-narrow) {
    gap: 15px;
    align-items: center;
    margin-top: 14px;
  }
}
@media (min-width: 1024px) {
  .weekly-schedule {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .weekl-schedule-content-1 {
    grid-row: 2 / 1;
    grid-column: 2 / 3;
  }
  .weekl-schedule-content-1 input {
    width: 88px!important;
    height: 38px!important;
  }
  .check-online-sessions {
    margin-top: 5px;
  }
  .weekl-schedule-content-2 {
    margin-bottom: 0px!important;
    align-items: baseline;
  }
  #content_onlineSession .field-body > .field:not(.is-narrow) {
    gap: 16px;
    align-items: center;
    margin-top: 14px;
  }
}

</style>
