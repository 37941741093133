import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async NewCourse (idToken, obj) {
    return await apiClient.post('courses/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async GetCourses () {
    return await apiClient.get('courses/')
  },
  async GetCourse (courseId) {
    return await apiClient.get(`courses/${courseId}`)
  },
  async ActivateCourse (idToken, courseId) {
    return await apiClient.post('class/course/',
    {
      courseId: courseId
    },
    {
      headers: { idToken: idToken }
    })
  },
  async DeleteCourse (CourseId, idToken) {
    return await apiClient.delete(`courses/${CourseId}`,
    {
      headers: { idToken: idToken }
    },)
  },
  async UpdateCourse (idToken, obj, courseId) {
    return await apiClient.post(`courses/${courseId}`, obj,
      {
        headers: { idToken: idToken }
      })
  },
}
