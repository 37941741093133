<template>
  <div id="change-class">

    <div class="header-modal">
      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold black">Editar Clase</h3>
        
        <!-- BUTTON CLOSE -->
        <a @click="closeModal">
          <div class="">
            <svg class="button-close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 2L2 18" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 2L18 18" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
    </div>
    
    <!-- CHANGE DATE -->
    <div class="change-date">
      <div class="container-change-date">
        <!-- FORM CHANGE CLASS -->
        <div class="form-change-class">
          <!-- START -->
          <div class="date-course">
            <b-field class="field-date" label="Fecha de clase">
              <b-datepicker
                v-model="dateSelected"
                :show-week-number="showWeekNumber"
                :locale="locale"
                placeholder="Seleccionar fecha"
                icon="calendar-today"
                trap-focus>
              </b-datepicker>
            </b-field>

            <div class="duration-hour">
              <b-field class="start" label="Comienzo">
                <b-select
                  v-model="startClassHour"
                  placeholder="Elige">
                    <option
                      v-for="(hour, index) in hoursOfDayStart"
                      :key="index" :value="hour">{{hour}}
                    </option>
                </b-select>
              </b-field>

              <b-field class="end ml-3" label="Final">
                <b-select
                  v-model="endClassHour"
                  placeholder="Elige">
                    <option
                      v-for="(hour, index) in hoursOfDayEnd"
                      :key="index" :value="hour">{{hour}}
                    </option>
                </b-select>
              </b-field>
              <b-field class="end ml-3" label="# Estudiantes permitido">
                <b-input
                  type="number"
                  v-model="maxStudents"
                  placeholder="24">
                </b-input>
              </b-field>
            </div>
            <div class="mentor-place">
  
                <!-- MENTOR -->
                <b-field class="mentor" label="Mentor">
                  <b-select 
                    v-model="profesorSelected" 
                    :placeholder='selectedClass.mentor.firstname + " " + selectedClass.mentor.lastname'>
                    <option
                      v-for="(profesor, idx) in this.profesores"
                      :value="profesor._id"
                      :key="idx"> {{ profesor.firstname + ' ' + profesor.lastname }}
                    </option>
                  </b-select>
                </b-field>

                <!-- PLACE -->
                <b-field class="place ml-2" label="Lugar de clase">
                  <b-select 
                    v-model="locationSelected"
                    :placeholder="selectedClass.location.name">
                    <option
                      v-for="(location, idx) in this.locations"
                      :value="location._id"
                      :key="idx">
                      {{ location.name}}
                    </option>
                  </b-select>
                </b-field>
              </div>
          </div>
        </div>
      </div>
      <div class="accionables">
        <div class="container-buttons mt-4">
          <button
            class="button-medium-stroke-black"
            @click.prevent="closeModal">Cancelar
            </button>
          <button
            class="button-primary-extra-medium mleft-button"
            @click.prevent="updateClass">
            Guardar</button>
        </div>    
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from "@/services/ProfileService";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import ClassService from "@/services/ClassService"
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "UpdateClass",
  components: {},
  created() {
    this.$store.commit('updateLocations')
    this.selectedClass = this.$store.state.selectedClass
    this.profesorSelected = this.selectedClass.mentor._id
    this.locationSelected = this.selectedClass.location._id
    this.startClassHour = this.getTimeFormatted(this.selectedClass.startclass)
    this.endClassHour = this.getTimeFormatted(this.selectedClass.endclass)
    this.dateSelected = new Date(dayjs(this.selectedClass.endclass).format('MM/DD/YYYY'))
    this.maxStudents = this.selectedClass.maxstudents
  },
  computed:  {
    profesores: function () {
      return this.allProfiles.filter(
        (profile) => profile.isMentor && profile.status === "active"
      );
    },
  },
  data() {
    return {
      authUser: this.$store.state.authUser,
      showWeekNumber: false,
      locale: undefined,
      email: null,
      password: null,
      firstname: "",
      lastname: "",
      phone: "",
      mensajeEmail: "",
      mensajePassword: "",
      mensajeFirst: "",
      mensajeLast: "",
      mensajePhone: "",
      messageTypeEmail: "",
      messageTypePassword: "",
      messageTypePhone: "",
      messageTypeFirst: "",
      messageTypeLast: "",
      valid: true,
      mensaje: "",
      passwordError: null,
      generalError: null,
      loader: false,
      bindProps: {
        mode: "international",
        preferredCountries: ["ES"],
        onlyCountries: [],
        ignoredCountries: [],
      },
      hoursOfDay: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'],
      hoursOfDayStart: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'],
      hoursOfDayEnd: null,

      locationSelected: null,
      profesorSelected: null,
      startClassHour: null,
      endClassHour: null,
      selectedClass: null,
      maxStudents: null,
      dateSelected: new Date(),
      locations: this.$store.state.locations,
      allProfiles: this.$store.state.allProfiles,
    };
  },

  watch: {
      startClassHour: function () {
        let index = this.hoursOfDay.indexOf(this.startClassHour) // if I choose '01:00' --> 2
        this.hoursOfDayEnd = this.hoursOfDay.slice(index+1)
      },
      endClassHour: function () {
        let index = this.hoursOfDay.indexOf(this.endClassHour) // if I choose '23:00' --> 47
        this.hoursOfDayStart = this.hoursOfDay.slice(0, index)
        
      },
  },

  // METHODS
  methods: {
    // Close modal
    closeModal() {
      this.$store.commit("closeModal");
    },  
    getTimeFormatted(date) {
      let time = dayjs(date)
      return time.format("HH:mm")
    },
    async updateClass() {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      if (this.maxStudents < this.selectedClass.bookings.length) {
        this.danger("Esta clase ya tiene más que " + this.maxStudents + " reservas")
        return
      }
      const updatedClass = await ClassService.UpdateClass( idToken, {
        classDate: this.dateSelected,
        startClassHour: this.startClassHour,
        endClassHour: this.endClassHour,
        location: this.locationSelected,
        mentor: this.profesorSelected,
        maxstudents: this.maxStudents
      }, this.selectedClass._id)
      if (updatedClass.status === 200) {
        this.$store.commit("updateCourseClasses", window.location.pathname.split("/")[3]);
        ClassService.GetClass()
          .then(response => {
            this.success("Guardado")
            this.closeModal()
            if (response.status === 200) this.$store.commit('updateAllClasses', response.data) 
          }).catch(()=>{})
      }
    },
    // Danger Toast
    danger(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-primary",
      });
    },
    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
  },
};
</script>


<style scoped>
.header-modal {
  width: 100%;
  background-color: #EFEFEF;
  height: 72px;
}

.mentor-place {
  display: flex;
  margin-top: 3px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.change-date {
  background-color: #fff;
  /*border: 1px solid #EDEDED;*/
  width: 40%;
  max-width: 882px;
  margin: auto;
  margin-top: 50px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.top {
  padding-bottom: 32px;
  border-bottom: 1px solid #ECECEC;
}

.form-change-class {
  margin-top: 30px;
}

.date-course {
  max-width: 417px;
}

.duration-hour {
  display: flex;
}

.hour-field {
  margin-left: 10px;
}

.mleft-button {
  margin-left: 10px;
}


@media only screen and (max-width: 800px) {
  .change-date {
    width: 60%;
  }
}

@media only screen and (max-width: 524px) {
  .change-date {
    width: 80%;
  }

  .date-course {
    max-width: 335px;
  }
}

@media only screen and (max-width: 436px) {

  .container-header-modal {
    width: 87%;
  }

  .container-header-modal h3 {
    font-size: 16px;
  }

  .button-close {
    width: 18px;
    height: 18px;
  }

  .header-modal {
    height: 60px;
  }

  .duration-hour {
    display: block;
  }

  .hour-field {
    margin-left: 0px;
  }

  .container-buttons {
    display: flex;
    padding-top: 30px;
  }
}
</style>
