<template>
  <div id="sign_up">
    <a @click="goHome">
      <div class="button-close has-text-right mr-5 mt-5">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 1L1 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L25 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </a>
    
    <div class="container-content-create-account has-text-left mt-5">

      <div class="title-header-section-create-account">
        <p class="">Registro Profesor</p>
      </div>

      <!-- Signup Form -->
      <form >

        <div class="name-user is-flex mt-5">
          <!-- Firstname -->
          <b-field
            label="Nombre(s)"
            :message="mensajeFirst"
            :type="messageTypeFirst"

          >
            <b-input
              v-on:focusout.native="ValidityFirstname"
              v-on:focusin.native="clearValidityFirst"
              id="firstname"
              v-model="firstname"
              autocomplete="off"
            >
            </b-input>
          </b-field>

          <!-- Lastname -->
          <b-field
            class="ml-2"
            label="Apellido(s)"
            :message="mensajeLast"
            :type="messageTypeLast"
          >
            <b-input
              v-on:focusout.native="ValidityLastname"
              v-on:focusin.native="clearValidityLast"
              id="lastname"
              v-model="lastname"
              autocomplete="off"
            >
            </b-input>
          </b-field>
        </div>

        <!-- eMail -->
        <b-field
          class="mt-3"
          label="Correo Electrónico"
          :message="mensajeEmail"
          :type="messageTypeEmail"
        >
          <b-input
            v-on:focusout.native="ValidityEmail"
            v-on:focusin.native="clearValidityEmail"
            :validation-message= mensaje
            id="email"
            placeholder="youremail@something.com"
            v-model="email"
            autocomplete="off"
          >
          </b-input>
        </b-field>

          <!-- phone -->
          <b-field
            class="mt-5"
            label="Teléfono móvil"
            :message="mensajePhone"
            :type="messageTypePhone"
            v-on:focusin.native="clearValidityPhone"
            autocomplete="off"
            >
            <vue-tel-input
              v-model="phone"
              v-bind="bindProps"
              id="phone"
              autocomplete="off"
              >
              </vue-tel-input>
          </b-field>

        <!-- Password -->
        <b-field
          class="mt-5 "
          label="Contraseña"
          :message="mensajePassword"
          :type="messageTypePassword"
        >
          <b-input
          v-on:focusout.native="ValidityPassword"
            v-on:focusin.native="clearValidityPassword"
            password-reveal
            id="password"
            type="password"
            v-model="password"
            autocomplete="off"
          >
          </b-input>
        </b-field>

        <!-- CTA Button -->
        <button class="button-primary-medium mt-6" @click.prevent="signup">Registrarme</button>
        
        <!-- <div v-if="loader">
          <Loading/>
        </div> -->

      </form>

    </div>

  </div>


</template>

<script>

import UserService from '@/services/UserService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'Signup',
  components: {
    VueTelInput
  },
  data () {
    return {
      authUser: this.$store.state.authUser,
      email: null,
      password: null,
      firstname: '',
      lastname: '',
      phone: '',
      mensajeEmail: '',
      mensajePassword: '',
      mensajeFirst: '',
      mensajeLast: '',
      mensajePhone: '',
      messageTypeEmail: '',
      messageTypePassword: '',
      messageTypePhone: '',
      messageTypeFirst: '',
      messageTypeLast: '',
      valid: true,
      mensaje: '',
      passwordError: null,
      generalError: null,
      loader: false,
      bindProps: {
        mode: 'international',
        preferredCountries: ['ES'],
        onlyCountries: [],
        ignoredCountries: []
      },
    }
  },

  // METHODS
  methods: {
    // switch to login
    loginModal () {
      this.$store.commit('openModal', 'login')
    },
    // Close modal
    goHome () {
      this.$router.push('/').catch(()=>{})
    },

    // Sign Up with Password --------
    async signup () {
      if (this.firstname && this.lastname && this.email && this.password) {
        this.valid = true
      }
      await this.ValidityFirstname()
      await this.ValidityLastname()
      await this.validPhone()
      await this.ValidityEmail()
      await this.ValidityPassword()


      if (this.valid) {
        // console.log('--------------------- hhhhhh99')
        this.loader = true
        await firebase.auth().createUserWithEmailAndPassword(this.email, this.password)

          .catch(err => {
            this.generalError = err.message
            this.danger(this.generalError)
          })

        // console.log('Worked?', this.$store.state.authUser)

        const newUser = await UserService.SignupFirebaseMentor(
          this.firstname,
          this.lastname,
          this.phone,
          this.$store.state.authUser.email,
          this.$store.state.authUser.uid,
        )

        if (newUser.status === 201) {
          this.$router.push('/panel/mis-perfiles')
          await firebase.auth().currentUser.getIdToken(true)
            .then(async idToken => {
              await UserService.SigninFirebase(idToken)
                .then(response => {
                  if (response.status === 200) {
                    // console.log('00', response.data)
                    this.$store.commit('updateUser', response.data)

                  }
                })
            })

        }

      }
    },
    ValidityFirstname () {
      if (!this.firstname) {
        this.mensajeFirst = 'Cuál es tu nombre'
        this.messageTypeFirst = 'is-primary'
        this.valid = false
      } else {
        this.mensajeFirst = ''
        // this.messageTypeFirst = 'is-success'
      }
    },
    ValidityLastname () {
      if (!this.lastname) {
        this.mensajeLast = 'Cuál es tu apellido'
        this.messageTypeLast = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLast = ''
        // this.messageTypeLast = 'is-success'
      }
    },
    validPhone () {
      if (!this.phone) {
        this.mensajePhone = 'Revisa tu número de teléfono'
        this.messageTypePhone = 'is-primary'
        document.getElementById('phone').classList.add('invalid-input')
        this.valid = false
      } else {
        this.mensajePhone = ''
        this.messageType = ''
      }
    },
    async ValidityEmail () {
      try {
        const response = await firebase.auth().fetchSignInMethodsForEmail(this.email)
        if (response.length > 0) {
          this.mensajeEmail = 'Este correo ya está registrado'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else if (!this.validEmail(this.email)) {
          this.mensajeEmail = 'Algo está mal. Revisa to correo electrónico.'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else {
          this.mensajeEmail = ''
          // this.messageTypeEmail = 'is-success'
        }
      } catch (error) {
        this.mensajeEmail = error.message
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      }
    },
    ValidityPassword () {
      if (this.password.length < 6 || this.password === '') {
        this.mensajePassword = 'La contraseña debe tener al menos 6 caracteres'
        this.messageTypePassword = 'is-primary'
        this.valid = false
      } else {
        this.mensajePassword = ''
        this.messageTypePassword = ''
      }
    },
    clearValidityFirst () {
      this.messageTypeFirst = ''
      this.mensajeFirst = ''
    },
    clearValidityLast () {
      this.messageTypeLast = ''
      this.mensajeLast = ''
    },
    clearValidityPhone () {
      this.mensajePhone = ''
      document.getElementById('phone').classList.remove('invalid-input')
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },
    clearValidityPassword () {
      this.mensajePassword = ''
      this.mensajePassword = ''
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}


</script>


<style scoped>
@import "../../../node_modules/vue-tel-input/dist/vue-tel-input.css";

#contact_welcome  .vue-tel-input {
  border: 1px solid #ECECEC !important;
}

/* #sign_up */

#sign_up input:-webkit-autofill,
#sign_up input:-webkit-autofill:hover,
#sign_up input:-webkit-autofill:active,
#sign_up input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-text-fill-color: #555555 !important;
}
#sign_up .has_required {
  text-decoration: line-through;
  color:#6e3dea;
}
#sign_up form {
    margin-top: 30px;
    width: 90%;
    margin: auto;
    max-width: 360px;
}

#sign_up .title-header-section-create-account {
  margin-top: 35px;
  margin-bottom: 45px;
}
#sign_up .container-content-create-account {
  width: 85%;
}

#sign_up input, #sign_up select {
    height: 45px !important;
}

#sign_up input::placeholder {
  font-family: 'Nunito' !important;
}

#sign_up input:hover {
  border-color: #EEEEEE;
}

.title-header-section-create-account {
  margin-top: 73px;
  margin: auto;
  width: 33%;
  margin: auto;
}

.title-header-section-create-account p {
  font-family: "Montserrat Bold";
  color: #000;
  font-size: 30px;
}

.link-text-sign {
  font-size: 12.5px;
}

@media only screen and (max-width: 890px) {

  .title-header-section-create-account p {
    font-size: 25px;
  }

  .title-header-section-create-account {
    width: 90%;
    margin: auto;
  }

  #sign_up .container-content-create-account {
    width: 90%;
    margin: auto;
  }
}

</style>
