<template>
  <div id="bookingTrue">
    <div class="content-modal">
      <div>
        <!-- ILUSTRATION MODAL -->
        <svg
          width="84"
          height="155"
          viewBox="0 0 84 155"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6452 16.4206C-4.89983 53.6204 -1.64574 112.413 4.03248 146.292C4.84965 151.168 9.12413 154.628 14.0678 154.628H53.5953C58.8233 154.628 63.247 150.773 63.8433 145.579C67.7557 111.497 71.3713 53.1549 48.4169 16.6197C41.6555 5.85803 26.6448 5.8123 19.6452 16.4206Z"
            fill="#2CB9B0"
            fill-opacity="0.54"
          />
          <path
            d="M17.9266 122.521L23.0458 130.003C24.3257 131.874 27.0781 131.897 28.3886 130.047L33.0636 123.449C34.4643 121.472 37.4568 121.664 38.5941 123.803L41.2689 128.835C42.4361 131.03 45.5344 131.159 46.8804 129.069L50.8897 122.841C52.0963 120.967 54.7871 120.832 56.1746 122.577L62.1095 130.039C63.4971 131.783 66.1878 131.649 67.3944 129.774L72.0022 122.617C73.194 120.766 75.8414 120.607 77.246 122.302L83.1256 129.399"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="1.62766"
            stroke-linecap="round"
          />
          <path
            d="M33.3672 154.628L33.3672 134.282"
            stroke="#2CB9B0"
            stroke-width="0.81383"
          />
          <path
            d="M33.3672 87.0798L33.3672 33.367"
            stroke="#2CB9B0"
            stroke-width="0.81383"
          />
          <path
            d="M32.8678 121.168C41.1318 121.219 47.8725 114.561 47.9237 106.297C47.9748 98.0334 41.317 91.2927 33.0531 91.2415C24.7891 91.1904 18.0484 97.8482 17.9972 106.112C17.9461 114.376 24.6039 121.117 32.8678 121.168Z"
            stroke="black"
            stroke-width="3.25532"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.9561 109.16C26.9561 109.16 29.182 112.167 32.9228 112.19C36.6636 112.213 38.9266 109.234 38.9266 109.234"
            stroke="black"
            stroke-width="3.25532"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M28.499 101.687L28.5178 101.687"
            stroke="black"
            stroke-width="3.25532"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M36.4551 101.687L36.4738 101.687"
            stroke="black"
            stroke-width="3.25532"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h3 class="montserrat-bold gray-medium">Tu pago ha sido exitoso</h3>
      </div>

      <!-- INFO PAY -->
      <div class="info-pay">
        <!-- NAME COURSE -->
        <p class="opensans gray-medium mt-2">
          Haz realizado un pago total de <b>{{amountPaid}}€ </b>. <br><br> Tu cuenta ha sido recargada con
          <b>{{creditsAdded}}€ </b> que ya están disponibles en tu perfil.
        </p>
      </div>

      <button @click="refresh" class="mt-6 button-primary-extra-medium">Aceptar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessPayment",
  data () {
    return {
      amountPaid: null,
      creditsAdded: null
    }
  },
  methods: {
    refresh() {
      this.$router.push('/panel/mis-perfiles').catch(()=>{})
    }
  },
  async created () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    this.amountPaid = urlParams.get('amountPaid')
    this.creditsAdded = urlParams.get('creditsAdded')
  }

}
</script>

<style scoped>
.header {
  background-color: #efefef;
  height: 85px;
  padding-top: 42px;
}

.container-header {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.info-pay {
  max-width: 315px;
}

.content-modal {
  width: 80%;
  margin: auto;
  max-width: 488px;
  margin-top: 150px;
}
</style>