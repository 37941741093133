import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async NewLocation (idToken, obj) {
    return await apiClient.post('location/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async UpdateLocation (idToken, obj, id_location) {
    return await apiClient.post(`location/${id_location}`, obj,
      {
        headers: { idToken: idToken }
      })
  },
  async DeleteLocation (idToken, id_location) {
    return await apiClient.delete(`location/${id_location}`,
      {
        headers: { idToken: idToken }
      })
  },

  async GetLocations () {
    return await apiClient.get('location/')
  },
  async GetLocation (id_location) {
    return await apiClient.get(`location/${id_location}`)
  }

}
