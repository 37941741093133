<template>
  <div id="app">

    <!-- Modal de Primer Orden-->
    <div id="modal" v-if="this.$store.state.modal">
      <Login
        v-if="this.$store.state.modalContent === 'login'"
      />
      <Signup
        v-if="this.$store.state.modalContent === 'signup'"
      />
      <Newprofile
        v-if="this.$store.state.modalContent === 'newprofile'"
      />
      
      <Uploadimage
        v-if="this.$store.state.modalContent === 'uploadimage'"
      />
      <NewCourse
        v-if="this.$store.state.modalContent === 'newcourse'"
      />
      <NewClass
        v-if="this.$store.state.modalContent === 'newclass'"
      />
      <NewLocation
        v-if="this.$store.state.modalContent === 'newlocation'"
      />
      <NewRecarga
        v-if="this.$store.state.modalContent === 'newrecarga'"
      />
      <NewStudentType
        v-if="this.$store.state.modalContent === 'newstudenttype'"
      />
      <ChangeClass
        v-if="this.$store.state.modalContent === 'changeclass'"
      />
      <CancelClass
        v-if="this.$store.state.modalContent === 'cancelclass'"
      />
      <AddCredits
        v-if="this.$store.state.modalContent === 'addcredits'"
      />
      <DetailClass
        v-if="this.$store.state.modalContent === 'detailclass'"
      />
      
    </div>

    <!-- Modal de Segundo Orden (Más adelante) -->
    <div id="modal2" v-if="this.$store.state.modal2">

      <Uploadimage
        v-if="this.$store.state.modalContent2 === 'uploadimage'"
      />
      <DetailClass
        v-if="this.$store.state.modalContent2 === 'detailclass'"
      />
   
    </div>

    <!-- Modal que sale de lado-->
    <div id="modalR" v-if="this.$store.state.modalR">
      <div
        @click="closeModalR"
        class="filter_modal"
      >
      </div>
      <NewBooking
        v-if="this.$store.state.modalContent === 'newbooking'"
      />
      <NewBono
        v-if="this.$store.state.modalContent === 'newbono'"
      />
    </div>


    <!-- Navbar USER -->
    <Navbar
      v-if="this.$store.state.navbar && this.$store.state.user && this.$store.state.user.typeofuser === 'User'"
    />

    <!-- Navbar ADMIN-->
    <NavbarAdmin
      v-if="this.$store.state.navbar && this.$store.state.user && this.$store.state.user.typeofuser === 'Admin'"
    />

    <!-- Navbar MENTOR-->
    <NavbarMentor
      v-if="this.$store.state.navbar && this.$store.state.user && this.$store.state.user.typeofuser === 'Mentor'"
    />

    <!-- NavbarProfile -->
    <NavbarProfile
      v-if="this.$store.state.navbarProfile"
    />


    <!-- NavbarProfile -->
    <NavbarAdminEstudiante
      v-if="this.$store.state.navbarAdminEstudiante"
    />

    <!-- NavbarProfile -->
    <NavbarAdminCurso
      v-if="this.$store.state.navbarAdminCurso"
    />

    <!-- NavbarLocation -->
    <NavbarAdminLocation
      v-if="this.$store.state.navbarAdminLocation"
    />



    <!-- App -->
    <router-view  />


  </div>
</template>

<script>
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import Login from '@/components/modals/login.vue'
  import Signup from '@/components/modals/signup.vue'
  import Uploadimage from '@/components/modals/uploadimage.vue'
  import Newprofile from '@/components/modals/newprofile.vue'
  import NewCourse from '@/components/modals/newcourse.vue'
  import NewClass from '@/components/modals/newclass.vue'
  import NewStudentType from '@/components/modals/type-student.vue'
  import ChangeClass from '@/components/modals/changeclass.vue'
  import CancelClass from '@/components/modals/cancelclass.vue'
  import NewBooking from '@/components/modals/newbooking.vue'
  import NewBono from '@/components/modals/newbono.vue'
  import NewLocation from '@/components/modals/newlocation.vue'
  import AddCredits from '@/components/modals/addcredits.vue'
  import NewRecarga from '@/components/modals/refill-admin.vue'
  import DetailClass from '@/components/modals/detailclass.vue'
  

  import Navbar from '@/components/navbar.vue'
  import NavbarProfile from '@/components/navbarProfile.vue'
  import NavbarAdmin from '@/components/navbarAdmin.vue'
  import NavbarMentor from '@/components/navbarMentor.vue'
  import NavbarAdminEstudiante from '@/components/navbarAdminEstudiante.vue'
  import NavbarAdminCurso from '@/components/navbarAdminCurso.vue'
  import NavbarAdminLocation from '@/components/navbarAdminLocation.vue'
  import UserService from '@/services/UserService'
  import ProfileService from '@/services/ProfileService'
  import CourseService from '@/services/CourseService'

  export default {
    name: 'App',
    components: {
      Login,
      Signup,
      Newprofile,
      Uploadimage,
      NewCourse,
      NewClass,
      ChangeClass,
      CancelClass,
      NewBooking,
      NewLocation,
      NewRecarga,
      NewStudentType,
      Navbar,
      NavbarProfile,
      NavbarAdmin,
      NavbarMentor,
      NavbarAdminEstudiante,
      NavbarAdminCurso,
      NavbarAdminLocation,
      AddCredits,
      DetailClass,
      NewBono
    },
    data () {
      return {
        // authUser: null
      }
    },

    // CREATED
    async created () {
      firebase.auth().onAuthStateChanged(async authUser => {
        if (authUser) {
          this.$store.commit('updateAuthUser', authUser._delegate)
          await firebase.auth().currentUser.getIdToken(true)
            .then(async idToken => {
              await UserService.SigninFirebase(idToken)
                .then(async response => {
                  if (response.status === 200) {
                    // console.log('00', response.data)
                    this.$store.commit('updateUser', response.data)
                    this.$store.commit('updateBonos')
                    await CourseService.GetCourses(idToken)
                    .then( courses => {
                      this.$store.commit('updateAllCourses', courses.data)
                    })
                    if (response.data.typeofuser === 'Admin') {
                      // console.log('---> 33', 'isAdmin')
                      this.$router.push('/admin/estudiantes').catch(()=>{})
                      await ProfileService.getAllProfiles(idToken)
                      .then( profiles => {
                          this.$store.commit('updateAllProfiles', profiles.data)
                      })
                    } else if (response.data.typeofuser === 'Mentor') {
                      // console.log('---> 34', 'isMentor')
                      setTimeout(() => {
                        this.$router.push(`/mentor/${this.$store.state.profiles[0]._id}/calendario`).catch(()=>{})
                      }, 1000)
                    } else if (response.data.typeofuser === 'User') {
                      // console.log('---> 33', 'isUser')
                      this.$router.push('/panel/cursos-activos').catch(()=>{})
                    }
                  }
                })
                .catch(()=>{})
              await ProfileService.getCurrentProfiles(idToken)
                .then(response => {
                  if (response.status === 200) {
                    // console.log('00', response)
                    this.$store.commit('updateProfiles', response.data)
                    this.$store.commit('updateCurrentProfile', window.location.pathname.split('/')[2])
                  }
                })
                .catch(()=>{})
              // await configurationService.getSkillLevel()
              //   .then(response => {
              //     this.$store.commit('updateSkills', response.data)
              //   })
              //   .catch(()=>{})
            })
        } else {
          this.$store.commit('updateAuthUser', null)
          this.$store.commit('updateUser', null)
          this.$store.commit('updateProfiles', null)
        }
      }),

      this.$store.commit('updateSkills')
      this.$store.commit('updateLocations')
      this.$store.commit('updateMentors')
      this.$store.commit('updateCourses')
      this.$store.commit('updateDiscounts')
      this.$store.commit('updateTypeOfStudents')
      if (this.$store.state.authUser) {
        this.$store.commit('updateBonos')
      }
    },

    //METHODS
    methods: {
      // Close modal
      closeModalR () {
        this.$store.commit('closeModalR')
        this.$store.commit('clearBookingDetails')
        this.$store.commit('clearBonoDetails')
      },

    }

  }

</script>

<style lang="scss">
#modal {
  background-color: white;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  position: fixed;
  color: white;
  top: 0;
  overflow-y: auto;
}

#modal2 {
  background-color: white;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 21;
  position: fixed;
  color: white;
  top: 0;
  overflow-y: auto;
}

#modalR {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 5;
  position: fixed;
  color: white;
  top: 0;
  overflow-y: auto;
}

.filter_modal {
  background-color: #000000ab;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  position: fixed;
  color: white;
  top: 0;
  overflow-y: auto;  
}

.space_65 {
  height: 65px;
}

</style>
