import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async NewBono (idToken, obj) {
    return await apiClient.post('bono/', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async GetMyBonos (idToken) {
    return await apiClient.get('bono/current',
      {
        headers: { idToken: idToken }
      })
  },
  // async DeleteBooking (idToken, class_id, student_id) {
  //   return await apiClient.post(`booking/class/${class_id}`,
  //     {
  //       student_id: student_id
  //     },
  //     {
  //       headers: { idToken: idToken }
  //     })
  // }
  // async GetClass (idToken) {
  //   return await apiClient.get('class/',
  //     {
  //       headers: { idToken: idToken }
  //     })
  // },
  // async GetCourseClasses (courseId) {
  //   return await apiClient.get(`class/course/${courseId}`)
  // }

}
