<template>
    <div id="level">
        <div class="container-level">
            <p class="montserrat-medium black title-step">
                1. Elige un  nivel</p>
            
            <!-- LEVEL COURSE -->
            <div
            v-for="(level, index) in levels"
                :key="index"
            class="container-buttons-level">
                <button
                 class="button-level" v-on:click="saveLevelSelection(level)"
                 v-bind:class="{bookingSelection: $store.state.reservationState.level && $store.state.reservationState.level === level}">
                 {{level}}</button>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  data () {
    return {
      levels: this.$store.state.skilllevels
    }
  },
  methods: {
      saveLevelSelection(selectedLevel) {
          let reservationState = this.$store.state.reservationState
          reservationState.level = selectedLevel
          this.$store.commit('updateReservation', reservationState)
          this.$store.commit('incrementBookingStep')
      }
  }
}
</script>

<style scoped>

.title-step {
    font-size: 15px;
    margin-top: 60px;
}

.container-buttons-level {
    margin-top: 25px;
}

.button-level {
    background-color: #F5F3F3;
    width: 280px;
    height: 64px;
    border-radius: 2px;
    border: none;
    color: #000;
    font-family: "Montserrat SemiBold";
    font-size: 14px;
    text-align: left;
    padding-left: 20px;
}

.button-level:hover {
    background-color: #FBDFB6;
}

</style>