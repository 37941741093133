<template>
  <div id="sign_up">
    <a @click="closeModal">
      <div class="button-close has-text-right mr-5 mt-5">
        <svg
          width="18"
          height="18"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 1L1 25"
            stroke="#000"
            stroke-width="4"
          />
          <path
            d="M1 1L25 25"
            stroke="#000"
            stroke-width="4"
          />
        </svg>
      </div>
    </a>
    
    <div class="container-content-create-account has-text-left mt-5">



      <!-- Signup Form -->
      <form >

        <div class="">
          <p class="title-new-profile title-section text-basier has-text-left title-regular dark-blue-emitus">
            Nuevo alumno</p>
        </div>

        <div class="name-user is-flex mt-5">
          <!-- Firstname -->
          <b-field
            label="Nombre(s)"
            :message="mensajeFirst"
            :type="messageTypeFirst"

          >
            <b-input
              v-model="firstname"
              v-on:focusout.native="ValidityFirstname"
              v-on:focusin.native="clearValidityFirst"
              id="firstname"
              autocomplete="off"
            >
            </b-input>
          </b-field>

          <!-- Lastname -->
          <b-field
            class="ml-2"
            label="Apellido(s)"
            :message="mensajeLast"
            :type="messageTypeLast"
          >
            <b-input
              v-on:focusout.native="ValidityLastname"
              v-on:focusin.native="clearValidityLast"
              id="lastname"
              v-model="lastname"
              autocomplete="off"
            >
            </b-input>
          </b-field>
        </div>

        <!-- date of birth -->
        <b-field
          class="date-of-birth mt-3"
          label="Fecha de nacimiento"
          :message="mensajeDateofbirth"
          :type="messageTypeDateofbirth"
        >

          <b-datepicker
            v-model="dateofbirth"
            placeholder="Agrega fecha..."
            
            :locale="locale"
            trap-focus
            editable
          >
          </b-datepicker>
        </b-field>




        <!-- eMail -->
        <!-- <b-field
          class="mt-3"
          label="Correo Electrónico"
          :message="mensajeEmail"
          :type="messageTypeEmail"
        >
          <b-input
            v-on:focusout.native="ValidityEmail"
            v-on:focusin.native="clearValidityEmail"
            :validation-message= mensaje
            id="email"
            placeholder="youremail@something.com"
            v-model="email"
            autocomplete="off"
          >
          </b-input>
        </b-field> -->

          <!-- phone -->
          <!-- <b-field
            class="mt-5"
            label="Teléfono móvil"
            :message="mensajePhone"
            :type="messageTypePhone"
            v-on:focusin.native="clearValidityPhone"
            autocomplete="off"
            >
            <vue-tel-input
              v-model="phone"
              v-bind="bindProps"
              id="phone"
              autocomplete="off"
              >
              </vue-tel-input>
          </b-field> -->


        <!-- CTA Button -->
        <button class="button-primary-medium mt-6" @click.prevent="newprofile">Crear nuevo alumno</button>


      </form>

    </div>

  </div>


</template>

<script>

import ProfileService from '@/services/ProfileService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'Signup',
  // components: {
  //   VueTelInput
  // },
  data () {
    return {
      authUser: this.$store.state.authUser,
      email: null,
      password: null,
      firstname: '',
      lastname: '',
      phone: '',
      mensajeEmail: '',
      mensajePassword: '',
      mensajeFirst: '',
      mensajeLast: '',
      mensajePhone: '',
      messageTypeEmail: '',
      messageTypePassword: '',
      messageTypePhone: '',
      messageTypeFirst: '',
      messageTypeLast: '',
      valid: true,
      mensaje: '',
      passwordError: null,
      generalError: null,
      loader: false,
      bindProps: {
        mode: 'international',
        preferredCountries: ['ES'],
        onlyCountries: [],
        ignoredCountries: []
      },
      dateofbirth: null,
      mensajeDateofbirth: '',
      messageTypeDateofbirth: '',
      locale: undefined, // Browser locale,
      date: new Date(),
    }
  },

  // METHODS
  methods: {

    // Close modal
    closeModal () {
      this.$store.commit('closeModal')
    },

    // Sign Up with Password --------
    async newprofile () {
      if (this.firstname && this.lastname && this.dateofbirth) {
        this.valid = true
      }
      await this.ValidityFirstname()
      await this.ValidityLastname()
      await this.ValidityDateofbirth()


      if (this.valid) {
        // console.log('--------------------- hhhhhh99')
        this.loader = true

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newProfile = await ProfileService.createNewProfile( idToken, {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          dateofbirth: this.dateofbirth
        }
        )
        


        if (newProfile.status === 201) {
          this.$store.commit('closeModal')
          await firebase.auth().currentUser.getIdToken(true)
            .then(async idToken => {
              await ProfileService.getCurrentProfiles(idToken)
                .then(response => {
                  if (response.status === 200) {
                    console.log('01', response)
                    this.$store.commit('updateProfiles', response.data)
                  }
                })
                .catch(()=>{})
            })

        }

      }
    },
    ValidityFirstname () {
      if (!this.firstname) {
        this.mensajeFirst = 'Cuál es tu nombre'
        this.messageTypeFirst = 'is-primary'
        this.valid = false
      } else {
        this.mensajeFirst = ''
        // this.messageTypeFirst = 'is-success'
      }
    },
    ValidityLastname () {
      if (!this.lastname) {
        this.mensajeLast = 'Cuál es tu apellido'
        this.messageTypeLast = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLast = ''
        // this.messageTypeLast = 'is-success'
      }
    },
    validPhone () {
      if (!this.phone) {
        this.mensajePhone = 'Revisa tu número de teléfono'
        this.messageTypePhone = 'is-primary'
        document.getElementById('phone').classList.add('invalid-input')
        this.valid = false
      } else {
        this.mensajePhone = ''
        this.messageType = ''
      }
    },
    ValidityDateofbirth () {
      if (!this.dateofbirth) {
        this.mensajeDateofbirth = 'Cuál es la fecha de nacimiento'
        this.messageTypeDateofbirth = 'is-primary'
        this.valid = false
      } else {
        this.mensajeDateofbirth = ''
        // this.messageTypeLast = 'is-success'
      }
    },
    async ValidityEmail () {
      try {
        if (!this.validEmail(this.email)) {
          this.mensajeEmail = 'Algo está mal. Revisa to correo electrónico.'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else {
          this.mensajeEmail = ''
          // this.messageTypeEmail = 'is-success'
        }
      } catch (error) {
        this.mensajeEmail = error.message
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      }
    },

    clearValidityFirst () {
      this.messageTypeFirst = ''
      this.mensajeFirst = ''
    },
    clearValidityLast () {
      this.messageTypeLast = ''
      this.mensajeLast = ''
    },
    clearValidityPhone () {
      this.mensajePhone = ''
      document.getElementById('phone').classList.remove('invalid-input')
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}


</script>


<style scoped>
@import "../../../node_modules/vue-tel-input/dist/vue-tel-input.css";

.title-new-profile {
  font-family: "Montserrat Bold";
  color: #272727;
  font-size: 30px;
  margin-top: 40px;
  margin-bottom: 45px;
}

#contact_welcome  .vue-tel-input {
  border: 1px solid #ECECEC !important;
}

/* #sign_up */

#sign_up input:-webkit-autofill,
#sign_up input:-webkit-autofill:hover,
#sign_up input:-webkit-autofill:active,
#sign_up input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-text-fill-color: #555555 !important;
}


#sign_up .has_required {
  text-decoration: line-through;
  color:#6e3dea;
}

#sign_up form {
  margin-top: 30px;
  width: 90%;
  margin: auto;
  max-width: 360px;
}

#sign_up .title-header-section-create-account {
  margin-top: 30px;
  margin-bottom: 45px;
  margin-left: 5%;
}

#sign_up .container-content-create-account {
  width: 85%;
}

#sign_up input, #sign_up select {
    height: 45px !important;
}

#sign_up input::placeholder {
  font-family: 'Nunito' !important;
}

#sign_up input:hover {
  border-color: #EEEEEE;
}

@media only screen and (max-width: 890px) {
  .title-new-profile {
    font-size: 25px;
  }

  #sign_up .container-content-create-account {
    width: 90%;
    margin: auto;
  }
}

</style>
