<template>
  <div id="pay">
    <div class="container-pay">
      <!-- REFILL -->
      <div class="refill">

        <div
          v-for="(discount, index) in $store.state.discountVols"
          :key= index
          class="container-refill"
          @click="payWithStripe(discount.amount)"
          >
          <div class="info-refill">
            <!-- AMOUNT REFILL -->

            <p class="montserrat-medium gray-light total-text">TOTAL</p>
            <p class="montserrat-bold black total-number">{{ discount.amount }} EUR</p>

            <!--
            <p class="montserrat-semibold gray-medium">{{ discount.amount }} EUR</p> -->

          </div>

          <div class="total mt-3">
            <!-- DCTO -->
            <p v-if="false" class="montserrat-semibold blue mt-2 percentage">+ {{ discount.discount }}%</p>

            <!--
            <p class="montserrat-bold black total-number">{{ CalculateRecharge(discount.amount, discount.discount) }} EUR</p>-->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PaymentService from "../../services/PaymentService"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

export default {
  name: "Configuracion",
  methods: {
    async payWithStripe(amount) {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      const obj = {
        amount: amount,
        user_id: this.$store.state.user._id
      }
      const response = await PaymentService.PayWithStripe(idToken, obj)
      window.location.href = response.data.url
    },
    CalculateRecharge(value, percentage) {
      let recharge = (1 + percentage / 100) * value
      return recharge.toFixed(2)
    },
  }

}


</script>

<style scoped>
.refill {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  max-width: 846px;
}

.container-refill {
  width: 90%;
  min-width: 235px;
  max-width: 245px;
  margin-left: 3%;
  margin-top: 3%;
  height: 146px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px 18px 0px;
  -webkit-box-shadow: 1px 4px 17px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 4px 17px 1px rgba(0, 0, 0, 0.1);
}

.info-refill {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 4px;
}

.amount-refill {
  font-size: 18px;
}

.percentage {
  font-size: 23px;
}

.total-text {
  font-size: 11px;
}

.total-number {
  font-size: 30px;
}

@media only screen and (max-width: 595px) {
  .refill {
    width: 95%;
  }

  .container-refill {
    width: 95%;
    max-width: 328px;
  }
}
</style>