<template>
  <div id="refill-admin">
    <div class="header-modal">
      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold black">Nueva tipo de estudiante</h3>

        <!-- BUTTON CLOSE -->
        <a @click="closeModal">
          <div>
            <svg
              class="button-close"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 2L2 18"
                stroke="black"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 2L18 18"
                stroke="black"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>

    <!-- FORM -->
    <div class="form-create-refill">
      <div class="container-form">
        <!-- ADD NEW TYPE STUDENT -->
        <form class="mt-6">

          <div class="max-campos mt-2">
            
            <!-- NOMBRE NEW TYPE STUDENT -->
            <b-field
              class=""
              label="Nombre tipo de estudiante"
            >
              <b-input
                id=""
                placeholder=""
                autocomplete="off"
                v-model="studentTypeName"
              >
              </b-input>
            </b-field>
            
            <!-- PERCENTAGE DCTO -->
            <b-field
              class="mt-2"
              label="Porcentaje dcto al estudiante"
            >
              <b-input
                id=""
                placeholder="%"
                type="number"
                autocomplete="off"
                v-model="discount"
              >
              </b-input>
            </b-field>
          </div>
        </form>

        <!-- button save information place of class -->

        <button
          class="mt-5 button-primary-extra-medium"
          v-on:click="createNewStudentType(studentTypeName, discount)">
          Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import configurationService from "../../services/ConfigurationService";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  data() {
    return {
      studentTypeName: '',
      discount: 0
    }
  },
  //METHODS
  methods: {
    // Close modal
    closeModal () {
      this.$store.commit('closeModal')
    },
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
    async createNewStudentType() {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      configurationService.newTypeOfStudent(idToken, {
        typeofstudent: this.studentTypeName,
        discount: this.discount
      }).then((response => {
        if (response.status === 201) {
          this.success("Listo!")
          this.closeModal()
          this.$store.commit('updateTypeOfStudents')
        }
      }))
    }
  }
};


</script>

<style scoped>
.header-modal {
  width: 100%;
  background-color: #efefef;
  height: 72px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.max-campos {
  max-width: 430px;
}

.container-form {
  width: 90%;
  max-width: 450px;
  margin: auto;
}

@media only screen and (max-width: 550px) {
  .header-modal {
    height: 60px;
  }

  .container-header-modal h3 {
    font-size: 15px;
  }

  .button-close {
    width: 16px;
    height: 16px;
  }

  .container-form {
    margin-top: 95px;
  }
}
</style>