<template>
  <div id="date">
    <div class="container-date">
      <p v-if="section === 'mis-perfiles'" class="montserrat-medium black title-step">
        3. Elige una fecha y hora
      </p>

      <p v-if="section === 'cursos-activos' || section === 'mis-bonos'" class="montserrat-medium black title-step">
        1. Elige una fecha y hora
      </p>


      <div class="list-dates">
        <div
          v-for="(clase, index) in $store.getters.courseClassesFormatedAvailable"
          :key="index"
          class="content-date"
          v-bind:class="{
            bookingSelection: $store.state.reservationState.date,
          }"
          v-on:click="saveDateSelection(clase._id)"
        >
          <!-- DATE CLASS -->
          <span class="montserrat-bold black is-size-small-text"> {{clase.startclass+ ','}}</span>
          <!-- HOUR CLASS -->
          <span class="montserrat black is-size-small-text"> {{clase.startclassHour+ ' '}} - {{clase.endclassHour+ ' '}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChooseClass',

  // CREATED
  created () {
    this.$store.commit('updateCourseClasses', this.$store.state.reservationState.course._id)
  },
  data () {
    return {
      section: window.location.pathname.split('/')[2]
    }
  },

  // computed:  {
  //   classesByCourse: function () {
  //     return ClassService.GetCourseClasses(this.$store.state.reservationState.course._id)
  //   }
  // },
  
  // async created () {
  //   const classesByCourse = ClassService.GetCourseClasses(this.$store.state.reservationState.course._id)
  // },

  methods: {
    saveDateSelection(classId) {
      let reservationState = this.$store.state.reservationState
      reservationState.class_id = classId
      this.$store.commit("updateReservation", reservationState)
      if (this.$store.state.reservationState.with_bono) {
        this.$store.commit("incrementBookingStep")
        this.$store.commit("incrementBookingStep")
      } else {
        this.$store.commit("incrementBookingStep")
      }
    },
  },
};
</script>

<style scoped>
.title-step {
  font-size: 15px;
  margin-top: 60px;
}

.list-dates {
  margin-top: 20px;
}

.content-date {
  background-color: #f5f3f3;
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  border-radius: 2px;
  max-width: 413px;
  margin-bottom: 10px;
}

.content-date:hover {
  background-color: #fbdfb6;
}

</style>